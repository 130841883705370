<template>
    <div class="zm-customers">
        <div class="timestamp-box">
            <div class="back-icon" @click="goBack()">
                <i class="fa-solid fa-circle-chevron-left"></i>
            </div>
            <div class="last-timestamp-text">{{lastTimestampText}}</div>
            <v-select
                    v-model="selectedRefreshOption"
                    label="label"
                    :options="refreshOptions"
                    :clearable="false"
                    style="width: 300px"></v-select>
        </div>
        <div class="header-text">
            <img style="margin-right: 10px; margin-bottom: 10px" :src="getLedSrc(serviceCenter.status)"/>
            {{serviceCenter.name}}: Lista clienti
        </div>
        <div class="service-link-text">
            <span @click="goToServicesView()" style="cursor: pointer">Vista servizi</span>
        </div>
        <div class="counter-box">
            <div class="single-status-counter" v-for="status in customerStatuses" :key="status">
                <div v-show="statusCounter(status) != 0">
                    <img style="margin-right: 5px; margin-bottom: 5px" :src="getLedSrc(status)"/>
                    <span style="font-size: 20px; font-weight: bold; margin-right: 15px">{{statusCounter(status)}}</span>
                </div>
            </div>
        </div>
        <div class="settings-box">
            <span style="font-size: 20px; font-weight: bold; margin-right: 10px">Stato attivazione</span>
            <v-select
                v-model="selectedEnableStatusOption"
                label="label"
                :options="enableStatusOptions"
                :clearable="false"
                style="width: 300px; margin-right: 25px"></v-select>
            <span style="font-size: 20px; font-weight: bold; margin-right: 10px">Stato</span>
            <v-select
                v-model="selectedStatusOption"
                label="label"
                :options="statusOptions"
                :clearable="false"
                style="width: 300px"></v-select>
            <div class="search-box">
                <input class="search-input" v-model="search" placeholder="Cerca clienti" />
            </div>
        </div>
        <loading :active='isLoading' :is-full-page="true" style="align-self: center; margin-top: 200px"/>
        <div class="customers-list">
            <Customer v-for="customer in filteredCustomers" :key="customer.id" :customer="customer" @update-customer="updateCustomersList"></Customer>
        </div>
    </div>
</template>

<script>

import moment from "moment";
moment.locale('it')

import _ from 'lodash';

import Loading from 'vue-loading-overlay';

import Customer from "@/components/zapmonitor_components/customers/single_customer.vue"

import ApiService from "@/javascript/api_service";

export default {
    name: 'ZMCustomers',
    components: {
        Loading,
        Customer,
    },

    data () {
        return {
            search: '',
            isLoading: false,
            skipPage: false,
            serviceCenter: {},
            customers: [],
            customerStatuses: ['OK', 'WARNING', 'ERROR', 'FATAL', 'UNDEFINED'],
            refreshOptions: [
                {
                    value: 'never',
                    label: 'Autorefresh: Disabilitato',
                    refreshTime: null
                },
                {
                    value: '15s',
                    label: 'Ogni 15 secondi',
                    refreshTime: 15000
                },
                {
                    value: '30s',
                    label: 'Ogni 30 secondi',
                    refreshTime: 30000
                },
                {
                    value: '1m',
                    label: 'Ogni minuto',
                    refreshTime: 60000
                },
                {
                    value: '2m',
                    label: 'Ogni 2 minuti',
                    refreshTime: 120000
                }
            ],
            selectedRefreshOption: {
                value: 'never',
                label: 'Autorefresh: Disabilitato',
                refreshTime: null
            },
            enableStatusOptions: [
                {
                    value: null,
                    label: 'Qualsiasi',
                },
                {
                    value: true,
                    label: 'Solo attivi',
                },
                {
                    value: false,
                    label: 'Solo inattivi',
                },
            ],
            selectedEnableStatusOption: {
                value: true,
                label: 'Solo attivi',
            },
            statusOptions: [
                {
                    value: null,
                    label: 'Qualsiasi',
                },
                {
                    value: 'OK',
                    label: 'OK',
                },
                {
                    value: 'NON OK',
                    label: 'NON OK',
                },
                {
                    value: 'WARNING',
                    label: 'WARNING',
                },
                {
                    value: 'ERROR',
                    label: 'ERROR',
                },
                {
                    value: 'FATAL',
                    label: 'FATAL',
                },
            ],
            selectedStatusOption: {
                value: null,
                label: 'Qualsiasi',
            },
            lastTimestamp: new Date(),
            refreshFunction: null
        }
    },

    methods: {
        goBack () {
            this.$router.push(`/centri_servizi`)
        },

        getLedSrc (status) {
            return `/images/led_${status}.png`
        },

        isSelectedRefreshOption (refreshOption) {
            return refreshOption.value == this.selectedRefreshOption.value
        },

        setSelectedRefreshOption (refreshOption) {
            this.selectedRefreshOption = refreshOption
        },

        statusCounter (customerStatus) {
            let customers = []

            if (customerStatus == 'UNDEFINED') {
                customers = _.filter(this.customers, (singleCustomer) => {
                    return !singleCustomer.enabled
                })

                return customers.length
            }

            customers = _.filter(this.customers, (singleCustomer) => {
                return singleCustomer.enabled && singleCustomer.status == customerStatus
            })

            return customers.length
        },

        goToServicesView () {
            this.$router.push(`/centri_servizi/${this.serviceCenter.id}/stato_servizi`)
        },

        refreshMethod () {
            this.refreshFunction = setInterval(() => {
                this.refreshData()
            }, this.selectedRefreshOption.refreshTime)
        },

        refreshData () {
            this.loadCustomers()
            this.lastTimestamp = new Date()
        },

        loadServiceCenter () {
            let serviceCenterId = this.$route.params.id

            ApiService.fetchServiceCenter(serviceCenterId)
                .then((response) => {
                    this.serviceCenter = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        loadCustomers () {
            this.isLoading = true

            let serviceCenterId = this.$route.params.id

            ApiService.fetchCustomers(serviceCenterId)
                .then((response) => {

                    if (response.data.length == 1) {
                        let customerId = _.head(response.data).id
                        this.skipPage = true
                        this.isLoading = false
                        this.$router.push({path:`/clienti/${customerId}/prodotti`, query:{skipPage: this.skipPage}})
                    }

                    let orderedList = _.orderBy(response.data, 'name')
                    this.customers = orderedList
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false
                })
        },

        updateCustomersList (customer) {
            let customersList = _.remove(this.customers, (singleCustomer) => {
                return singleCustomer.id != customer.id
            })

            customersList = _.concat(customersList, customer)

            this.customers = _.orderBy(customersList, 'name')
        }
    },

    computed: {
        lastTimestampText () {
            let formattedTimestamp = moment(this.lastTimestamp).format('LTS')
            return `Ultimo refresh: ${formattedTimestamp}`
        },

        searchedCustomers () {
            if (!this.search) return this.customers

            return this.customers.filter(item => {
                let customerName = item.name || ""

                return customerName.toLowerCase().includes(this.search.toLowerCase())
            })
        },

        filteredCustomers () {

            if (this.selectedEnableStatusOption.value == null && !this.selectedStatusOption.value) return this.searchedCustomers

            let filteredCustomers = this.searchedCustomers

            if (this.selectedEnableStatusOption.value != null) {
                let activeCustomers = _.filter(this.searchedCustomers, (singleCustomer) => {
                    return singleCustomer.enabled
                })

                let inactiveCustomers = _.filter(this.searchedCustomers, (singleCustomer) => {
                    return !singleCustomer.enabled
                })

                filteredCustomers = this.selectedEnableStatusOption.value ? activeCustomers : inactiveCustomers
            }

            if (!this.selectedStatusOption.value) return filteredCustomers

            if (this.selectedStatusOption.value == 'NON OK') {
                filteredCustomers = _.filter(filteredCustomers, (singleCustomer) => {
                    return singleCustomer.status != 'OK'
                })
            }
            else {
                filteredCustomers = _.filter(filteredCustomers, (singleCustomer) => {
                    return singleCustomer.status == this.selectedStatusOption.value
                })
            }

            return filteredCustomers
        },
    },

    watch: {
        selectedRefreshOption (newValue) {
            this.$cookies.set('selected_refresh_option',newValue);
            clearInterval(this.refreshFunction)

            if (newValue.value != 'never') {
                this.refreshMethod()
            }
        }
    },

    mounted () {
        if (this.$cookies.get('selected_refresh_option') != null) {
            this.selectedRefreshOption = this.$cookies.get('selected_refresh_option')  // return value
        }

        this.loadServiceCenter()
        this.loadCustomers()
    },

    beforeUnmount () {
        clearInterval(this.refreshFunction)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "src/stylesheet/zm_commons/zm_commons";

.zm-customers {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.customers-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

</style>