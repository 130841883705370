<template>
    <div class="zm-single-service-center-row" @click="goToClientDetail()">
        <img style="height: 30px; margin-right: 30px" :src="getLedSrc(serviceCenter.status)"/>
        <div class="service-center-description">
            <span>{{serviceCenter.name}}</span>
            <div class="muted-counter" v-if="hasMutedContracts" v-tooltip.right="mutedTooltip">
                <i class="fa-solid fa-bell-slash"></i>
<!--                <span>CLIENTI:</span>-->
<!--                <span>{{serviceCenter.customer_muted_counter}}</span>-->
            </div>
<!--            <div class="muted-counter" v-if="hasMutedContracts">-->
<!--                <i class="fa-solid fa-bell-slash"></i>-->
<!--                <span>SERVIZI:</span>-->
<!--                <span>{{serviceCenter.service_muted_counter}}</span>-->
<!--            </div>-->
        </div>
        <div class="service-center-notes">
<!--            <span v-html="serviceCenter.status_message"></span>-->
            <div class="notes-text">{{serviceCenter.status_message}}</div>
        </div>
    </div>
</template>

<script>


export default {

    props: {
        serviceCenter: Object
    },

    data () {
        return {

        }
    },

    methods: {
        getLedSrc (serviceCenterStatus) {
            return `/images/led_${serviceCenterStatus}.png`
        },

        goToClientDetail () {
            this.$router.push(`centri_servizi/${this.serviceCenter.id}/clienti`)
        },
    },

    computed: {
        hasMutedContracts () {
            return this.serviceCenter.customer_muted_counter > 0
        },

        mutedTooltip () {
            let customer = this.serviceCenter.customer_muted_counter == 1 ? 'cliente' : 'clienti '
            let service = this.serviceCenter.service_muted_counter == 1 ? 'servizio silenziato' : 'servizi silenziati'

            return `${this.serviceCenter.service_muted_counter} ${service} in ${this.serviceCenter.customer_muted_counter} ${customer}`
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zm-single-service-center-row {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin: 0 10px;
    padding: 20px;
    align-items: center;
    cursor: pointer;

    &:nth-child(2n-1) {
        background-color: #f1f1f1;
    }

    &:hover {
        background: #ccc;
        border-radius: 6px;
    }
}

.muted-counter {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 16px;
}

.service-center-description {
    display: flex;
    font-size: 24px;
    font-weight: bold;
}

.service-center-notes {
    /*display: flex;*/
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    width: 50%;
    background: #f7f7f7;
    border: 1px solid #3d5171;
    border-radius: 6px;
    margin-left: auto;
    height: 100%;
    overflow: auto;
}

.notes-text {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    overflow: auto;
    white-space: pre-line;
}

</style>