<template>
    <ZMHeader></ZMHeader>
    <ZapMonitor/>
    <ZMFooter></ZMFooter>
</template>

<script>
import ZapMonitor from './components/ZapMonitor.vue'
import ZMHeader from './components/zapmonitor_layouts/header.vue'
import ZMFooter from './components/zapmonitor_layouts/footer.vue'

export default {
    name: 'App',
    components: {
        ZapMonitor,
        ZMHeader,
        ZMFooter
    }
}
</script>

<style>
#app {
    display: flex;
    height: 100%;
    flex-direction: column;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}
</style>
