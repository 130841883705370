<template>
    <div class="zm-single-service-center-block" v-bind:class="getBorderStyle(serviceCenter.status)" @click="goToClientDetail()">
        <div class="service-center-description">
            <span style="text-align: center">{{serviceCenter.name}}</span>
        </div>
        <img :src="getLedSrc(serviceCenter.status)"/>
        <div class="icon-box d-flex">
            <div class="muted-counter" v-if="hasMutedContracts" v-tooltip.bottom="mutedTooltip">
                <i class="fa-solid fa-bell-slash"></i>
<!--                <span>CLIENTI:</span>-->
<!--                <span>{{serviceCenter.customer_muted_counter}}</span>-->
            </div>
        </div>
    </div>
</template>

<script>


import _ from "lodash";

export default {

    props: {
        serviceCenter: Object
    },

    data () {
        return {
            customerStatuses: ['OK', 'WARNING', 'ERROR'],
        }
    },

    methods: {
        getLedSrc (serviceCenterStatus) {
            return `/images/led_${serviceCenterStatus}.png`
        },

        getBorderStyle (serviceCenterStatus) {
            return {
                'ok-border': serviceCenterStatus == 'OK',
                'warning-border': serviceCenterStatus == 'WARNING',
                'error-border': serviceCenterStatus == 'ERROR',
                'fatal-border': serviceCenterStatus == 'FATAL',
            }
        },

        goToClientDetail () {
            this.$router.push(`centri_servizi/${this.serviceCenter.id}/clienti`)
        },
    },

    computed: {
        hasMutedContracts () {
            return this.serviceCenter.customer_muted_counter > 0
        },

        mutedTooltip () {
            let customer = this.serviceCenter.customer_muted_counter == 1 ? 'cliente' : 'clienti '
            let service = this.serviceCenter.service_muted_counter == 1 ? 'servizio silenziato' : 'servizi silenziati'

            return `${this.serviceCenter.service_muted_counter} ${service} in ${this.serviceCenter.customer_muted_counter} ${customer}`
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zm-single-service-center-block {
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 10px;
    align-items: center;
    cursor: pointer;
    border-radius: 20px;

    /*&:hover {*/
    /*    background: #ccc;*/
    /*    border-radius: 6px;*/
    /*}*/
}

.ok-border {
    border: 7px solid #00C853;
    background: linear-gradient(#a6daa6, #E8F5E9);
}

.warning-border {
    border: 7px solid #FFD600;
    background: linear-gradient(#d7c79d, #FFFDE7);
}

.error-border {
    border: 7px solid #D50000;
    background: linear-gradient(#d9a5a5, #FFEBEE);
}

.fatal-border {
    border: 8px solid #262626;
    background: #FBFBFB
}

.service-center-description {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    margin: 20px 0;
}

.muted-counter {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 20px;
}

.service-center-notes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    /*width: 50%;*/
    background: #f7f7f7;
    border: 1px solid #3d5171;
    border-radius: 6px;
    /*margin-left: auto;*/
    /*height: 100%;*/
    padding: 10px;
    margin-top: 10px;
    overflow: auto;
}

.notes-text {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    overflow: auto;
    /*white-space: pre-line;*/
}

</style>