<template>
    <div class="zm-statistics-chart">
        <div class="chartdiv" ref="chartdiv" v-show="!dataNotPresent"></div>
        <div class="chart-data-not-present" v-show="dataNotPresent">
            <span>Dati non presenti con i filtri selezionati</span>
        </div>
    </div>
</template>

<script>

import _ from "lodash";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import DateService from "@/javascript/format_dates_service";

export default {
    name: 'ZMChart',

    props: {
        graphData: Object,
        graphType: String,
        search: String,
        filterData: Boolean
    },

    data () {
        return {
            chart: null,
        }
    },

    methods: {
        formatLineChartData () {
            if (_.isEmpty(this.chartData)) return []

            let dates = Object.keys(this.chartData[0]).filter(key => key !== '*');

            return dates.map(date => {

                let formattedDate = DateService.toItalian(date)

                let formattedData = {date: formattedDate}

                _.forEach(this.chartData, (singleData) => {
                    formattedData[`${singleData['*']}`] = singleData[`${date}`] || 0
                })

                return formattedData
            })
        },

        formatPieChartData () {
            if (_.isEmpty(this.chartData)) return []

            return this.chartData.map(data => {
                    return {category: data['*'], value: data['Valore']}
            })
        },

        initChart () {
            this.graphType == 'pie' ? this.createPieChart() : this.createLineChart()
        },

        createLineChart () {
            // am4core.useTheme(am4themes_animated);

            let chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

            let formattedData = this.formatLineChartData()

            chart.data = formattedData;

            chart.colors.step = 2;

            chart.legend = new am4charts.Legend();

            chart.legend.scrollable = true;

            let legendContainer = am4core.create("legenddiv", am4core.Container);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);
            legendContainer.logo.disabled = 'true'

            chart.legend.parent = legendContainer;

            let xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
            xAxis.dataFields.category = 'date'
            xAxis.renderer.cellStartLocation = 0.1
            xAxis.renderer.cellEndLocation = 0.9
            xAxis.renderer.grid.template.location = 0;

            let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
            yAxis.maxPrecision = 0;
            yAxis.min = 0;

            _.forEach(this.chartData, (column) => {
                this.createLineSeries(chart, column['*'], column['*'] )
            })

            chart.logo.disabled = 'true'

            this.chart = chart;
        },

        createLineSeries(chart, value, name) {
            let series = chart.series.push(new am4charts.LineSeries())
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'date'
            series.name = name

            let circleBullet = series.bullets.push(new am4charts.CircleBullet());
            circleBullet.circle.strokeWidth = 1;

            circleBullet.tooltipText = `${name} - {categoryX}: {valueY}`;

            return series;
        },

        createPieChart () {
            // am4core.useTheme(am4themes_animated);

            let chart = am4core.create("chartdiv", am4charts.PieChart);
            chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

            chart.legend = new am4charts.Legend();
            chart.legend.valueLabels.template.disabled = true;
            chart.legend.scrollable = true;

            chart.radius = am4core.percent(70);
            chart.innerRadius = am4core.percent(40);
            // chart.startAngle = 180;
            // chart.endAngle = 360;

            let legendContainer = am4core.create("legenddiv", am4core.Container);
            legendContainer.width = am4core.percent(100);
            legendContainer.height = am4core.percent(100);
            legendContainer.logo.disabled = 'true'

            chart.legend.parent = legendContainer;

            chart.data = this.formatPieChartData()

            let series = chart.series.push(new am4charts.PieSeries3D());
            series.dataFields.value = "value";
            series.dataFields.category = "category";
            series.labels.template.disabled = true;
            series.ticks.template.disabled = true;
            series.colors.step = 2;

            chart.logo.disabled = 'true'

            this.chart = chart;
        },

        sumDatesValues(obj) {
            let somma = 0;
            for (const key in obj) {
                if (key !== '*') {
                    const value = obj[key];
                    if (typeof value === 'number') {
                        somma += value;
                    }
                }
            }
            return somma;
        }
    },

    computed: {
        columnData () {
            if (!this.graphData) return []

            return this.graphData.data.columnDefs
        },

        chartData () {
            if (!this.graphData) return []

            let data = this.graphData.data.data

            let reducedData = data.sort((a, b) => this.sumDatesValues(b) - this.sumDatesValues(a));

            if (!this.search && this.filterData) return _.slice(reducedData, 0, 10)
            if (!this.search) return reducedData

            reducedData = reducedData.filter(item => {
                let rowField = item['*'] || ""

                return rowField.toLowerCase().includes(this.search.toLowerCase())
            })

            if (this.filterData) return _.slice(reducedData, 0, 10)

            return reducedData
        },

        dataNotPresent () {
            return _.isEmpty(this.chartData)
        }
    },

    watch: {
        chartData () {
            am4core.disposeAllCharts();
            this.initChart()
        },

        search () {
            am4core.disposeAllCharts();
            this.initChart()
        }

    },

    mounted () {
        this.initChart()
    },

    onUnmounted () {
        am4core.disposeAllCharts();
    }
}
</script>

<style lang="scss">

.zm-statistics-chart {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.chartdiv {
    height: 100%;
    padding: 15px 15px 0 15px;
}

.chart-data-not-present {
    align-self: center;
    margin-top: 160px;
    color: #b52a2f;
    font-size: 20px;

}


</style>