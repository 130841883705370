<template>
  <div class="single-data-node">
      <div class="node-row">
          <span class="icon is-right" style="margin-right: 8px; cursor: pointer; pointer-events: all" @click="toggleNodeData" v-if="hasChildren && this.data != null && !showChildren">
             <i class="fas fa-angle-right"></i>
          </span>
          <span class="icon is-right" style="margin-right: 8px; cursor: pointer; pointer-events: all" @click="toggleNodeData" v-if="hasChildren && this.data != null && showChildren">
             <i class="fas fa-angle-down"></i>
          </span>
          <span style="font-weight: bold">{{node}}</span>
          <div v-if="!hasChildren">
              <span>: {{dataText}}</span>
          </div>
      </div>
      <div v-if="hasChildren && showChildren">
          <data-node v-for="(group, key) in data" :key="key" :node="key" :data="group" ref="dataNodes"></data-node>
      </div>
  </div>
</template>

<script>

// import DataNode from "@/components/zapmonitor_components/services/single_notification_event_data_node.vue"

export default {

    name: 'data-node',

    components: {
        // DataNode
    },

    props: {
        node: [Number, String],
        data: [Boolean, Number, String, Object]
    },

    data () {
        return {
            showChildren: true
        }
    },

    methods: {
        toggleNodeData () {
            this.showChildren = !this.showChildren
        },
        showChildrenRecursive(value) {
            this.showChildren = value;
            this.$nextTick(() => {
                if (this.hasChildren && this.$refs.dataNodes) {
                    this.$refs.dataNodes.forEach(dataNode => {
                        dataNode.showChildrenRecursive(value);
                    });
                }
            });
        }
    },

    computed: {
        hasChildren () {
            return (typeof this.data === 'object') && !!this.data
        },

        dataText () {
            return !this.data ? 'Non disponibile' : `${this.data}`
        }
    }
}
</script>

<style>

.single-data-node {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.node-row {
    display: flex;
}

</style>