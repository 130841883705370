<template>
    <div class="zm-login">
        <div class="zm-login-box">
                <div class="zm-login-alert">
                <span style="text-align: center">{{alertText}}</span>
            </div>
            <input class="zm-login-input" v-model="username" placeholder="Nome utente" @keyup.enter="signIn">
            <input class="zm-login-input" v-model="password" placeholder="Password" type="password" @keyup.enter="signIn">
            <button class="zm-login-button" @click="signIn">
                <div v-if="loadingSession" class="spinner-border spinner-border-sm"></div>
                <span> Accedi </span>
            </button>
        </div>
    </div>
</template>

<script>

import SessionService from "@/javascript/session_service";

export default {
    name: 'ZMLogin',
    components: {

    },
    data () {
        return {
            alertText: 'Autenticati al sistema per proseguire',
            loadingSession: false,
            username: '',
            password: ''
        }
    },

    methods: {
        signIn () {
            this.loadingSession = true

            SessionService.login(this.username, this.password)
                .then((response) => {
                    this.loadingSession = false
                    this.$cookies.set('zapmonitor-session_id', response.data.session_id);
                    this.$cookies.set('zapmonitor-username', response.data.username);
                    this.$router.push('/centri_servizi')
                })
                .catch((error) => {
                    this.loadingSession = false
                    console.log(error);
                    this.alertText = 'Credenziali non corrette'
                });
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.zm-login {
    display: flex;
    flex: 1;
    justify-content: center;
}

.zm-login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background: #f7f7f7;
    border: 1px solid #3d5171;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2.5rem 5rem 0 rgba(0, 0, 0, 0.1);
    height: 40%;
    width: 20%;
    margin-top: 75px;
}

.zm-login-alert {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2dede;
    color: #b94a48;
    height: 50px;
    width: 100%;
}

.zm-login-input {
    background: white;
    height: 44px;
    padding: 5px;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid #ccc;
    margin-top: 20px;
    width: 75%;
}

.zm-login-button {
    background: #b52a2f;
    color: white;
    border: 1px solid #ccc;
    padding: 10px 16px;
    border-radius: 6px;
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 10px;
    width: 75%;
    cursor: pointer;
}

.header-text {
    font-size: 30px;
    align-self: center;
    margin-right: auto;
    margin-left: 15px;
    text-align: center;
}

</style>