<template>
    <div class="zm-header">
        <div class="zm-header-text">Zap Monitor</div>
        <div class="version-text">backend: v {{be_version}} - frontend: v {{ fe_version }}</div>
        <div class="route-menu" v-if="isLoggedIn">
            <router-link v-for="tab in routeOptions"
                         :key="tab.path"
                         :to="`/${tab.path}`"
                         v-bind:class="{active: isTabActive(tab.path)}"
                         style="display: flex; padding: 25px; text-decoration: none">
                <i :class="tab.icon" style="align-self: center; font-size: 15px; margin-right: 5px"></i>
                <span class="route-text">{{tab.title}}</span>
            </router-link>
        </div>
        <div class="admin-info" v-if="isLoggedIn" @mouseenter="openDropdown" @mouseleave="closeDropdown" @click="toggleDropdown" v-click-outside="closeDropdown">
            <div class="dropdown">
                <i class="fas fa-user" style="align-self: center; font-size: 20px; margin-right: 10px"></i>
                <span class="user-text">{{username}}</span>
                <i :class="`fa-solid fa-${dropdownIcon}`" style="align-self: center; font-size: 15px; margin-right: 10px" ></i>
                <ul v-if="isDropdownOpen" class="dropdown-menu">
                    <li @click="logout"><a><i class="fa-solid fa-right-from-bracket" style="align-self: center; margin-right: 8px"></i><span>Logout</span></a></li>
                </ul>
            </div>
            <!--            <button variant="outline-danger" class="logout-btn" @click="logout">Logout</button>-->
        </div>
    </div>
</template>

<script>

import _ from 'lodash';

import Alerts from "@/javascript/lib/alerts";

import ApiService from "@/javascript/api_service";
import SessionService from "@/javascript/session_service";
import config from "@/javascript/config"

import * as am4core from "@amcharts/amcharts4/core";

export default {
    name: 'ZMHeader',

    data () {
        return {
            be_version: '',
            fe_version: '',
            username: '',
            currentRoute: '',
            routeOptions: [
                {
                    path: 'centri_servizi',
                    title: 'HOME',
                    icon: 'fas fa-home',
                },
                {
                    path: 'statistiche',
                    title: 'STATISTICHE',
                    icon: 'fas fa-chart-pie',
                },
                // {
                //     path: 'user_manager',
                //     title: 'GESTIONE UTENTI',
                //     icon: 'fas fa-users',
                // }
            ],
            isDropdownOpen: false,
            isLoggedIn: this.$cookies.get('zapmonitor-session_id') != null
        }
    },

    methods: {
        getVersion () {

            ApiService.fetchBeVersion()
                .then((version) => {
                    this.be_version = version
                })
            config.fetchFeVersion()
                .then((version) => {
                    this.fe_version = version
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        logout () {
            Alerts.areYouSure(() => {
                let sessionId = this.$cookies.get('zapmonitor-session_id');
                SessionService.logout(sessionId)

                this.$cookies.remove('zapmonitor-session_id')
                this.$cookies.remove('zapmonitor-username')

                this.$router.push('/login')
            }, {title: '', text: 'Sei sicuro di voler eseguire il logout da questa sessione?'})
        },

        isTabActive (path) {
            let centriServiziChildrenActiveArray = ['root', 'clienti', 'stato_servizi', 'prodotti', 'servizi']
            let centriServiziChildrenActive = path == 'centri_servizi' && _.includes(centriServiziChildrenActiveArray, this.currentRoute)

            return path == this.currentRoute || centriServiziChildrenActive
        },

        toggleDropdown () {
            this.isDropdownOpen = !this.isDropdownOpen;
        },

        closeDropdown () {
            this.isDropdownOpen = false
        },

        openDropdown () {
            this.isDropdownOpen = true
        }
    },

    computed: {
        dropdownIcon () {
            return this.isDropdownOpen ? 'sort-up' : 'sort-down'
        }
    },

    watch:{
        $route (to){
            am4core.disposeAllCharts()
            this.isLoggedIn = this.$cookies.get('zapmonitor-session_id') != null
            this.currentRoute = to.name
        }
    },

    mounted () {
        this.getVersion()

        this.username = this.$cookies.get('zapmonitor-username')
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.zm-header {
    display: flex;
    flex-direction: row;
    height: 60px;
    border-bottom: 1px solid #3d5171;
    background: #f7f7f7;
}

.zm-header-text {
    font-size: 30px;
    align-self: center;
    margin-left: 15px;
    text-align: center;
}

.version-text {
    font-size: 10px;
    align-self: flex-end;
    margin-right: 30px;
    margin-left: 15px;
    margin-bottom: 15px;
}

.route-menu {
    display: flex;
    justify-content: center;
}

.active {
    background: #b52a2f;
    color: white;

    &:hover {
        color: white;
    }
}

a {
    color: #3d5171;

    &:hover {
        color: #b52a2f;
    }
}

.route-text {
    font-size: 20px;
    align-self: center;
}

.admin-info {
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: 10px;
    cursor: pointer;
}

.user-text {
    font-size: 18px;
    align-self: center;
    margin-right: 15px;
    margin-top: 5px;
}

.dropdown {
    position: relative;
    display: flex;
}

.dropdown button {
    background-color: #3490dc;
    color: #fff;
    border: none;
    cursor: pointer;
}

.dropdown-menu {
    position: absolute;
    background-color: #fff;
    list-style: none;
    margin: 0;
    padding: 0;
    left: -50px;
    border: 1px solid #ccc;
    display: block; /* Initially hide the menu */
}

.dropdown-menu li {
    padding: 8px;
}

.dropdown-menu li:hover {
    background-color: #f0f0f0;
}

.dropdown-menu a {
    text-decoration: none;
    color: #333;
}

.logout-btn {
    align-self: center;
    cursor: pointer;
    border: 1px solid #b52a2f;
    padding: 10px;
    border-radius: 6px;
    color: #b52a2f;

    &:hover {
        background: #ccc;
    }
}

</style>