<template>
  <div class="zm-statistics-table">
      <div ref="table" v-show="!dataNotPresent"></div>
      <div class="table-data-not-present" v-show="dataNotPresent">
          <span>Dati non presenti con i filtri selezionati</span>
      </div>
  </div>
</template>

<script>

import moment from 'moment'

import _ from 'lodash'

import {TabulatorFull as Tabulator} from 'tabulator-tables';
import DateService from "@/javascript/format_dates_service";

export default {

    name: 'ZMTable',

    props: {
        graphData: Object,
        search: String,
        filterData: Boolean
    },

    data() {
        return {
            tabulator: null, //variable to hold your table
        }
    },

    methods: {

        initTable () {
            this.tabulator = new Tabulator(this.$refs.table, {
                data: this.formatTableChartData(),
                layout:"fitColumns",
                columns: this.formatTableColumnData(),
                columnDefaults:{
                    minWidth:180
                },
            });
        },

        formatTableChartData () {
            if (_.isEmpty(this.rowData)) return []

            let dates = Object.keys(this.rowData[0]).filter(key => key != '*' &&  key !== 'Valore');

            if (_.isEmpty(dates)) {return this.rowData.map(singleData => {
                return {'': singleData['*'], Valore: singleData['Valore']}
            })}

            return this.rowData.map(singleData => {
                let formattedData = {'': singleData['*']}

                _.forEach(dates, (date) => {
                    let formattedDate = DateService.toItalian(date)
                    formattedData[`${formattedDate}`] = singleData[`${date}`] || 0
                })

                return formattedData
            })
        },

        formatTableColumnData () {
            if (_.isEmpty(this.columnData)) return []

            let dates = _.filter(this.columnData, (singleColumn) => {
                singleColumn.field != 'Valore'
            })

            if (dates.length == 1) {return this.columnData}

            return this.columnData.map(singleColumn => {
                if (singleColumn.field == '*') {return {field: '', frozen: true, title: '', hozAlign:"left", width: 400}}

                let formattedDate = DateService.toItalian(singleColumn.field)

                return {field: formattedDate, frozen: false, title: formattedDate, hozAlign: 'center', headerHozAlign: 'center'}
            })

        },

        sumDatesValues(obj) {
            let somma = 0;
            for (const key in obj) {
                if (key !== '*') {
                    const value = obj[key];
                    if (typeof value === 'number') {
                        somma += value;
                    }
                }
            }
            return somma;
        }
    },

    computed: {
        columnData () {
            if (_.isEmpty(this.graphData)) return []

            let mappedColumns = _.map(this.graphData.data.columnDefs, (singleColumn) => {
                let field = singleColumn.field
                let frozen = singleColumn.field == '*'

                return {title: field, field: field, frozen: frozen}
            })

            return mappedColumns
        },

        rowData () {
            if (_.isEmpty(this.graphData)) return []

            let data = this.graphData.data.data

            let reducedData = data.sort((a, b) => this.sumDatesValues(b) - this.sumDatesValues(a));

            if (!this.search && this.filterData) return _.slice(reducedData, 0, 10)
            if (!this.search) return reducedData

            reducedData = reducedData.filter(item => {
                let rowField = item['*'] || ""

                return rowField.toLowerCase().includes(this.search.toLowerCase())
            })

            if (this.filterData) return _.slice(reducedData, 0, 10)

            return reducedData
        },

        dataNotPresent () {
            return _.isEmpty(this.rowData)
        }
    },

    watch: {
        rowData () {
            this.initTable()
        },
    },

    mounted() {
        this.initTable()
    }
}
</script>

<style lang="scss">

@import  "tabulator-tables";

.zm-statistics-table {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  padding: 10px;
}

.table-data-not-present {
  align-self: center;
  margin-top: 150px;
  color: #b52a2f;
  font-size: 20px;
}

</style>