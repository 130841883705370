<template>
    <div class="zm-single-product" @click="goToProductDetail()">
        <img style="height: 30px; margin-right: 30px" :src="getLedSrc(product.status)"/>
        <div class="product-description">
            <span>{{product.name}}</span>
            <div class="muted-counter" v-if="hasMutedContracts" v-tooltip.right="mutedTooltip">
                <i class="fa-solid fa-bell-slash"></i>
                <span>{{product.muted_service_contracts_count}}</span>
            </div>
        </div>
        <div class="product-notes">
            <span class="notes-text">{{product.status_message}}</span>
        </div>
    </div>
</template>

<script>


export default {

    props: {
        customer: Object,
        product: Object
    },

    methods: {
        getLedSrc (productStatus) {
            return `/images/led_${productStatus}.png`
        },

        goToProductDetail () {
            this.$router.push(`/clienti/${this.customer.id}/prodotti/${this.product.id}/servizi`)
        },
    },

    computed: {
        hasMutedContracts () {
            return this.product.muted_service_contracts_count > 0
        },

        mutedTooltip () {
            let service = this.product.muted_service_contracts_count == 1 ? 'servizio silenziato' : 'servizi silenziati'

            return `${this.product.muted_service_contracts_count} ${service}`
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zm-single-product {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin: 5px 10px;
    padding: 10px 40px;
    align-items: center;
    cursor: pointer;

    &:nth-child(2n-1) {
        background-color: #f1f1f1;
    }

    &:hover {
        background: #ccc;
        border-radius: 6px;
    }
}

.product-description {
    display: flex;
    font-size: 24px;
    font-weight: bold;
}

.product-notes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    width: 50%;
    background: #f7f7f7;
    border: 1px solid #3d5171;
    border-radius: 6px;
    margin-left: auto;
    height: 45px;
    overflow: auto;
}

.notes-text {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    white-space: pre;
}

.muted-counter {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 16px;
}

</style>