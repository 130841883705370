// Importa le librerie necessarie: Axios per le chiamate HTTP e moment per la manipolazione delle date
import axios from 'axios';
import moment from "moment";
moment.locale('it')

// Crea un'istanza di Axios per le chiamate HTTP
const axiosInstance = axios.create();

/**
 * Funzione helper per effettuare chiamate GET con Axios.
 * @param {string} endpoint - L'endpoint dell'API da chiamare.
 * @param {Object} [params={}] - Parametri da passare nella query string.
 * @returns {Promise} Una promessa che risolve con la risposta dell'API.
 */
function apiGet(endpoint, params = {}) {
    return axiosInstance.get(endpoint, { params });
}

function fetchStatistics() {
    return apiGet('/api/statistics', {});
}

function fetchStatisticsGraphData(id, params) {
    return apiGet('/api/statistics/graph_data', {id: id, statistics_args: params});
}

export default {
    fetchStatistics,
    fetchStatisticsGraphData,
}