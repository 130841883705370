<template>
    <div class="zm-single-service" :class="{ selected: isSelected }">
        <span class="mute-icon" @click="updateCustomerMuted(service.service_contract)">
            <i :class="muteIcon(service.service_contract)"></i>
        </span>
        <div class="clickable-row" @click="$emit('selectService')">
            <img style="height: 30px; margin-right: 30px" :src="getLedSrc(service.service_status.status)"/>
            <div class="service-description">
                <span style="font-size: 24px; font-weight: bold; margin-right: auto">{{service.name}}</span>
                <span style="font-size: 12px; margin-right: auto" v-if="heartbeatPresent">{{getHeartbeatText(service.heartbeat.next_at)}}</span>
            </div>
            <div class="service-notes">
                <span class="notes-text">{{service.service_status.status_message}}</span>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment";
import ApiService from "@/javascript/api_service";
moment.locale('it')

export default {

    props: {
        customer: Object,
        service: Object,
        selectedService: Object
    },

    methods: {
        getLedSrc (serviceStatus) {
            return `/images/led_${serviceStatus}.png`
        },

        getHeartbeatText (nextHb) {
            return `Prossimo heartbeat atteso: ${moment(nextHb).fromNow()} (${moment(nextHb).format('lll')})`
        },

        muteIcon (serviceContract) {
            if (serviceContract.is_muted) {
                return "fa-solid fa-bell-slash"
            }

            return "fa-solid fa-bell"
        },


        updateCustomerMuted (serviceContract) {
            ApiService.updateServiceContract(this.customer.id, serviceContract.id, {is_muted: !serviceContract.is_muted})
                .then((response) => {
                    this.$emit('update-service-contract');
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    },

    computed: {
        isSelected () {
            return this.selectedService.id == this.service.id
        },

        heartbeatPresent () {
            return this.service.heartbeat != null
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zm-single-service {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin: 0 10px;
    /*align-items: center;*/
    cursor: pointer;

    &:nth-child(2n-1) {
        background-color: #f1f1f1;
    }

    &:hover {
        background: #ccc;
        border-radius: 6px;
        color: #b52a2f;
    }
}

.mute-icon {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 22px;
    cursor: pointer;
    pointer-events: all;
    width: 30px;
    padding: 20px;
}

.clickable-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 20px;
    align-items: center;
}

.selected {
    background-color: #3d5171 !important;
    color: white;
    border-radius: 6px;

    &:hover {
        color: white;
    }
}

.service-description {
    display: flex;
    flex-direction: column;
}

.service-notes {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    width: 50%;
    background: #f7f7f7;
    border: 1px solid #3d5171;
    border-radius: 6px;
    margin-left: auto;
    height: 100%;
}

.notes-text {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
    color: #3d5171;
}

</style>