import Swal from 'sweetalert2';

let defaultOptions = {
    heightAuto: false,
    allowOutsideClick: false,
    showConfirmButton: true,
    showCancelButton: true,
}

function error(customOptions = {}) {
    let options = {
        title: 'Si è verificato un errore!',
        text: 'Prova a ricaricare la pagina',
        icon: 'error',
        confirmButtonText: 'Ricarica la pagina',
        cancelButtonText: 'Chiudi',
        ...defaultOptions,
        ...customOptions
    }

    Swal.fire(options).then((result) => {
        if (result.value) {
            location.reload(true);
        }
    })
}

function areYouSure(callback, customOptions = {}) {
    let options = {
        title: 'Sei sicuro?',
        text: 'Sei sicuro di voler eseguire questa operazione?',
        icon: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Annulla',
        ...defaultOptions,
        ...customOptions
    }

    Swal.fire(options).then((result) => {
        if (result.value) {
            callback();
        }
    })
}

function generic(customOptions = {}, callback = null) {
    let options = {
        title: 'Message',
        text: '',
        icon: 'info',
        confirmButtonText: 'Ok',
        cancelButtonText: 'Chiudi',
        ...defaultOptions,
        ...customOptions
    }

    Swal.fire(options).then((result) => {
        if (result.value && callback) {
            callback();
        }
    })
}

function notification (customOptions = {}) {
    let options = {
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        showConfirmButton: false,
        timer: 2500,
        timerProgressBar: true,
        ...customOptions,
    }

    Swal.fire(options)
}

export default { error, areYouSure, generic, notification }