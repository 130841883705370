<template>
  <div class="zm-footer">
      <div class="footer-text">&copy; 2023 Intersail engineering s.r.l.</div>
      <div class="footer-logo">
          <img style="align-self: center" :src="logo"/>
      </div>
  </div>
</template>

<script>

import logoIntersail from "./../../../public/images/logo_intersail.png"

export default {
    name: 'ZMFooter',
    data () {
        return {
            logo: logoIntersail
        }
    },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "src/stylesheet/zm_commons/zm_footer";

</style>