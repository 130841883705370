<template>
    <div class="zm-statistics" v-bind:class="{ disabled: loadingData }">
        <div class="zm-statistics-filters-bar">
            <v-select
                v-model="selectedFilters.type"
                label="name"
                :options="typeOptions"
                :clearable="false"
                :reduce="type => type.id"
                @option:selected="updateData(false)"
                style="width: 310px"></v-select>
            <v-select
                v-show="frequencyOptions.length > 0"
                v-model="selectedFilters.frequency"
                label="name"
                :options="frequencyOptions"
                :clearable="false"
                :reduce="type => type.value"
                style="width: 250px; margin-left: 15px"></v-select>
            <v-select
                v-model="selectedFilters.period"
                label="name"
                :options="periodOptions"
                :clearable="false"
                :reduce="type => type.value"
                @option:selected="setDates"
                style="width: 250px; margin-left: 15px"></v-select>
            <span style="margin-left: 15px; font-weight: bold; align-self: center">Da:</span>
            <VueDatePicker
                v-model="selectedFilters.dateFrom"
                :clearable="false"
                :enable-time-picker="false"
                :disabled="disableDatePicker || loadingData"
                locale="it"
                format="d/L/y"
                style="width: 150px; margin-left: 10px; align-self: center"
                auto-apply ></VueDatePicker>
            <span style="margin-left: 15px; font-weight: bold; align-self: center">A:</span>
            <VueDatePicker
                v-model="selectedFilters.dateTo"
                :clearable="false"
                :enable-time-picker="false"
                :disabled="disableDatePicker || loadingData"
                locale="it"
                format="d/L/y"
                style="width: 150px; margin-left: 10px; align-self: center"
                auto-apply ></VueDatePicker>
            <button variant="outline-danger" class="update-btn" @click="updateData(true)">
                <div v-if="loadingData" class="spinner-border spinner-border-sm"></div>
                <span style="margin-left: 10px">Aggiorna dati</span>
            </button>
        </div>
        <div class="zm-statistics-body">
            <div class="chart-box">
                <Table v-if="!showChart" :graph-data="graphData" :search="search" :filter-data="filterData"></Table>
                <Chart v-if="showChart" :graph-data="graphData" :graph-type="graphType" :search="search" :filter-data="filterData"></Chart>
            </div>
            <div class="chart-user-filter">
                <div class="graph-btn-box">
                    <button
                        type="button"
                        class="graph-btn"
                        v-for="option in graphOptions"
                        :key="option.value"
                        :class="{'active': selectedFilters.graph === option.value}"
                        @click="onChangeShowOptions(option.value)">
                        <span>{{option.name}}</span>
                        <!--                        <i style="font-size: 18px" :class="`fa-solid fa-${option.icon}`"></i>-->
                    </button>
                </div>
                <div class="filter-toggle-box">
                    <Toggle v-model="filterData" class="toggle-blue"/>
                    <span style="align-self: center; font-size: 12px">VISUALIZZA I PRIMI 10 RECORD</span>
                </div>
                <div class="search-box" style="margin: 10px">
                    <input class="search-input" style="width: 100%" v-model="search" placeholder="Filtra risultati per nome" />
                </div>
                <div class="legenddiv" ref="legenddiv" v-if="showChart"></div>
            </div>
        </div>
        <div class="zm-statistics-action-bar">
            <button variant="outline-danger" class="export-btn" @click="exportCsv" v-show="exportDataPresent">
                <span>Esporta dati</span>
            </button>
        </div>
    </div>
</template>

<script>

import moment from 'moment'
moment.locale('it');

import _ from "lodash"

import Toggle from '@vueform/toggle'

import * as am4core from "@amcharts/amcharts4/core";

import Chart from "@/components/zapmonitor_components/statistics/chart.vue";
import Table from "@/components/zapmonitor_components/statistics/table.vue";

import StatisticsService from "@/javascript/statistics_service";
import DateService from "@/javascript/format_dates_service";

export default {
    name: 'ZMStatistics',
    components: {
        Toggle,
        Chart,
        Table,
    },

    data () {
        return {
            loadingData: false,
            selectedFilters: {
                type: 6,
                graph: 'table',
                dateFrom: moment().subtract(7, 'days'),
                dateTo: moment(),
                frequency: 'day',
                period: 'p'
            },
            search: '',
            filterData: true,
            typeOptions: [],
            frequencyOptions: [
                {
                    value: 'day',
                    name: 'Giornaliero'
                },
                {
                    value: 'month',
                    name: 'Mensile'
                },
                {
                    value: 'year',
                    name: 'Annuale'
                }
            ],
            periodOptions: [
                {
                    value: 'p',
                    name: 'Date personalizzate'
                },
                {
                    value: '1w',
                    name: 'Ultima settimana'
                },
                {
                    value: '15dd',
                    name: 'Ultimi 15 giorni'
                },
                {
                    value: '1m',
                    name: 'Ultimo mese'
                },
                {
                    value: '1y',
                    name: 'Anno corrente'
                }


            ],
            graphOptions: [
                {
                    value: 'table',
                    name: 'TABELLA',
                    icon: 'table'
                },
                {
                    value: 'graph',
                    name: 'GRAFICO',
                    icon: 'chart-line'
                }
            ],
            data: {}
        }
    },

    methods: {
        onChangeShowOptions (value) {
            if (value == 'table') {am4core.disposeAllCharts()}
            this.selectedFilters.graph = value
        },

        setFilters () {
            let dateFrom = _.filter(this.graphFilters, (filter) => {return filter.name == 'dt_start'})
            let dateTo = _.filter(this.graphFilters, (filter) => {return filter.name == 'dt_end'})
            let frequency = _.filter(this.graphFilters, (filter) => {return filter.name == 'frequency'})

            this.selectedFilters['period'] = 'p'
            this.selectedFilters['dateFrom'] = dateFrom[0].default
            this.selectedFilters['dateTo'] = dateTo[0].default

            if (!_.isEmpty(frequency)) {
                this.selectedFilters['frequency'] = frequency[0].default
                this.frequencyOptions = frequency[0].values
            } else {
                this.frequencyOptions = []
            }
        },

        setDates (period) {
            switch (period.value) {
                case '1w':
                    this.selectedFilters.dateFrom = moment().subtract(7, 'days')
                    this.selectedFilters.dateTo = moment()
                    return
                case '15dd':
                    this.selectedFilters.dateFrom = moment().subtract(15, 'days')
                    this.selectedFilters.dateTo = moment()
                    return
                case '1m':
                    this.selectedFilters.dateFrom = moment().subtract(1, 'months')
                    this.selectedFilters.dateTo = moment()
                    return
                case '1y':
                    this.selectedFilters.dateFrom = moment().startOf('year');
                    this.selectedFilters.dateTo = moment()
                    return
                default:
                    return
            }
        },

        getStatisticTypesOptions () {
            StatisticsService.fetchStatistics()
                .then((response) => {
                    this.typeOptions = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        getStatisticsGraphData (useFilters) {
            this.loadingData = true

            let dtStart = moment(this.selectedFilters.dateFrom).format('DD/MM/YYYY')
            let dtEnd = moment(this.selectedFilters.dateTo).format('DD/MM/YYYY')
            let frequency = this.selectedFilters.frequency

            let params = useFilters ? {"filters":{},"graph_type":{"label":"Tabella","name":"table","$$hashKey":"object:8283"},"dt_start": `${dtStart}`,"dt_end": `${dtEnd}`,"frequency": `${frequency}`} : {}

            StatisticsService.fetchStatisticsGraphData(this.selectedFilters.type, params)
                .then((response) => {
                    this.data = response.data
                    if (!useFilters) {this.setFilters()}
                    this.loadingData = false
                })
                .catch((error) => {
                    this.loadingData = false
                    console.log(error);
                })
        },

        updateData (useFilters) {
            this.getStatisticsGraphData(useFilters)
        },

        exportCsv () {
            let formattedExportData = this.exportDataFormatted(this.exportData.data)

            let csvContent = this.convertToCsv(formattedExportData)
            let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
            let url = URL.createObjectURL(blob)
            let link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'export_data.csv')
            link.click()
        },

        exportDataFormatted (data) {
            if (_.isEmpty(data)) return []

            let dates = Object.keys(data[0]).filter(key => key != '*' &&  key !== 'Valore');

            if (_.isEmpty(dates)) {return data.map(singleData => {
                return {'': singleData['*'], Valore: singleData['Valore']}
            })}

            return data.map(singleData => {
                let formattedData = {'': singleData['*']}

                _.forEach(dates, (date) => {
                    let formattedDate = DateService.toItalian(date)
                    formattedData[`${formattedDate}`] = singleData[`${date}`] || 0
                })

                return formattedData
            })
        },

        convertToCsv (data) {
            let headers = Object.keys(data[0])
            let rows = data.map(obj => headers.map(header => obj[header]))
            let headerRow = headers.join(',')
            let csvRows = [headerRow, ...rows.map(row => row.join(','))]

            return csvRows.join('\n')
        }
    },

    computed: {
        showChart () {
            return this.selectedFilters.graph == 'graph'
        },

        graphData () {
            if (_.isEmpty(this.data)) return []

            return this.data.graph_data
        },

        graphType () {
            if (_.isEmpty(this.data)) return []

            let graphType = _.filter(this.data.graph_types, (graphType) => {
                return graphType.name != 'table'
            })

            return graphType[0].name
        },

        graphFilters () {
            if (_.isEmpty(this.data)) return []

            let filters = this.data.graph_filters

            return filters.map(filter => {

                let values = null

                if (filter.values != null) {
                    values = filter.values.map(value => {
                        return {value: value[1], name: value[0]}
                    })
                }

                return {name: filter.name, values: values, default: filter.default}
            })
        },

        exportData () {
            if (_.isEmpty(this.data)) return []

            return this.data.export_data
        },

        exportDataPresent () {
            if (_.isEmpty(this.data)) return false

            return this.exportData.data.length > 0
        },

        disableDatePicker () {
            return this.selectedFilters.period != 'p'
        }
    },

    watch: {},

    mounted () {
        this.getStatisticTypesOptions()
        this.getStatisticsGraphData(false)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">

@import "src/stylesheet/zm_commons/zm_commons";
@import "@vueform/toggle/themes/default.css";

.zm-statistics {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: auto;
}

.disabled {
    pointer-events: none;
    //background: #f3f3f3;
    opacity: 0.33;
}

.zm-statistics-filters-bar {
  display: flex;
  flex-direction: row;
  border: 1px solid #3d5171;
  border-radius: 6px;
  margin: 10px;
  padding: 10px;
    align-items: center;
}

.dp__pointer {
    height: 100%;
}

.graph-btn-box {
    margin-top: 20px;
    align-self: center;
}

.graph-btn {
    height: 40px;
    width: 115px;
    border: 1px solid #3d5171;
    background: white;
    color: #3d5171;
    border-radius: 6px;

    &:hover {
        background: #efefef;
    }

    &:nth-child(2n-1) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    &:nth-child(2n) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

.active {
    background: #ccc;
}

.filter-toggle-box {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.toggle-blue {
    display: flex;
    justify-content: center;
    padding: 20px;
    --toggle-bg-on: #5682bb;
    --toggle-border-on: #5682bb;
}

.toggle-container:focus {
    box-shadow: 0 0 0 0;
}

.update-btn {
    align-self: center;
    cursor: pointer;
    border: 1px solid #b52a2f;
    padding: 10px;
    border-radius: 6px;
    background: #b52a2f;
    color: white;
    margin-left: auto;
    width: 150px;
}

.zm-statistics-body {
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: auto;
}

.chart-box {
    display: flex;
    margin: 10px;
    border: 1px solid #3d5171;
    border-radius: 6px;
    flex: 4;
    overflow: auto;
}

.chart-user-filter {
    display: flex;
    flex-direction: column;
    margin: 10px;
    border: 1px solid #3d5171;
    border-radius: 6px;
    flex: 1
}

.legenddiv {
    height: 100%;
    margin-top: 20px;
}

.zm-statistics-action-bar {
    display: flex;
    height: 70px;
}

.export-btn {
    align-self: center;
    cursor: pointer;
    border: 1px solid #b52a2f;
    padding: 10px;
    border-radius: 6px;
    background: #b52a2f;
    color: white;
    margin: 10px 15px 10px auto;
}

</style>