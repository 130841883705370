// Importa il modulo axios per effettuare chiamate HTTP
import axios from 'axios';

// Variabile per memorizzare la versione una volta recuperata
let version = null;

/**
 * Recupera la versione del frontend.
 * Se la versione è già stata recuperata in precedenza, la restituisce direttamente.
 * Altrimenti, effettua una chiamata API per ottenerla.
 *
 * @returns {Promise<string>} La versione del frontend.
 */
async function fetchFeVersion() {
  // Se la versione non è stata ancora recuperata, effettua la chiamata API
  if (!version) {
    try {
      const response = await axios.get('/version'); // Effettua la chiamata API per ottenere la versione
      version = response.data.version; // Memorizza la versione recuperata
    } catch (error) {
      // Gestisce eventuali errori durante la chiamata API
      console.error("Error fetching config:", error);
      version = 'unknown'; // Imposta un valore di default per la versione in caso di errore
    }
  }
  return version; // Restituisce la versione
}

// Esporta la funzione fetchFeVersion come proprietà di un oggetto
export default {
  fetchFeVersion
};
