import { createApp } from 'vue'
import App from './App.vue'

import router from './router.js'

import VueCookies from 'vue-cookies'

import VueSelect from "vue-select"
import VueDatePicker from '@vuepic/vue-datepicker'
import FloatingVue from 'floating-vue'
import vClickOutside from "click-outside-vue3"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'floating-vue/dist/style.css'

import "vue-select/dist/vue-select.css"

import '@vuepic/vue-datepicker/dist/main.css'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

createApp(App)
    .use(router)
    .use(require('vue-cookies'))
    .use(VueCookies, { expire: '1d'})
    .use(FloatingVue)
    .use(vClickOutside)
    .component("v-select", VueSelect)
    .component('VueDatePicker', VueDatePicker)
    .mount('#app')


// Now the app has started!