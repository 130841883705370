// Importa il modulo Axios per effettuare richieste HTTP
import axios from 'axios';

// Crea un'istanza di Axios per configurare le richieste HTTP in modo centralizzato
const axiosInstance = axios.create();

/**
 * Effettua il login dell'utente.
 *
 * @param {string} username - Il nome utente per l'autenticazione.
 * @param {string} password - La password per l'autenticazione.
 * @returns {Promise} Una promessa che risolve con la risposta del server (es. token di autenticazione).
 */
function login(username, password) {
    return axiosInstance.post('/sessions', { username, password });
}

/**
 * Ottiene informazioni sulla sessione corrente dell'utente.
 *
 * @returns {Promise} Una promessa che risolve con le informazioni sulla sessione corrente.
 */
function session() {
   return axiosInstance.get('/sessions');
}

/**
 * Termina la sessione corrente dell'utente.
 *
 * @param {string} sessionId - L'ID della sessione da terminare.
 * @returns {Promise} Una promessa che risolve quando la sessione è stata terminata con successo.
 */
function logout(sessionId) {
    return axiosInstance.delete(`/sessions/${sessionId}`);
}

// Esporta le funzioni per permettere l'utilizzo in altri moduli del progetto
export default {
    login,      // Funzione di login
    session,    // Funzione per ottenere informazioni sulla sessione
    logout,     // Funzione di logout
};
