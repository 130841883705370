<template>
    <div class="zm-services">
        <div class="timestamp-box">
            <div class="back-icon" @click="goBack()">
                <i class="fa-solid fa-circle-chevron-left"></i>
            </div>
            <div class="last-timestamp-text">{{lastTimestampText}}</div>
            <v-select
                    v-model="selectedRefreshOption"
                    label="label"
                    :options="refreshOptions"
                    :clearable="false"
                    style="width: 300px"></v-select>
        </div>
        <div class="header-text">
            <img style="margin-right: 10px; margin-bottom: 10px" :src="getLedSrc(customer.status)"/>
            {{customer.name}}: Lista servizi
        </div>
        <div class="services-box">
            <div class="products-box">
                <div class="settings-box" style="margin: 0 10px" v-if="products.length > 0">
                    <span style="font-size: 20px; font-weight: bold; margin-right: 10px">Prodotto</span>
                    <v-select
                        v-model="selectedProduct"
                        label="name"
                        :options="products"
                        :disabled="products.length < 2"
                        :clearable="false"
                        style="width: 300px; margin-right: 15px"></v-select>
                    <span style="font-size: 20px; font-weight: bold; margin-right: 10px">Stato</span>
                    <v-select
                        v-model="selectedStatusOption"
                        label="label"
                        :options="statusOptions"
                        :clearable="false"
                        :open="true"
                        style="width: 300px"></v-select>
                    <div class="search-box">
                        <input class="search-input" v-model="search" placeholder="Cerca servizi" />
                    </div>
                </div>
                <loading :active='isLoadingServices' :is-full-page="true" style="align-self: center; margin-top: 150px"/>
                <div class="services-list" v-if="!isLoadingServices && filteredServices.length > 0">
                    <Service v-for="service in filteredServices" :key="`${service.service_contract.id}_${service.service_contract.is_muted}`" :service="service" :customer="customer" :selected-service="selectedService" @select-service="selectService(service)" @update-service-contract="serviceContractUpdated"></Service>
                </div>
            </div>
            <div class="events-box">
                <div class="events-box-header">
                    <span style="margin-right: auto; font-size: 24px; font-weight: bold">Eventi</span>
                    <button
                        type="button"
                        class="events-btn"
                        title="Mostra / nascondi solo i cambiamenti di stato"
                        @click="onShowOnlyStatusChangesChange()"
                        :class="{'active':showOnlyStatusChanges}">
                        <i class="fas fa-compress-alt"></i>
                    </button>
                </div>
                <loading :active='isLoadingNotifications' :is-full-page="true" style="align-self: center; height: 100%; margin-top: 50%"/>
                <div class="notifications-list" v-if="notificationsPresent" @scroll="handleNotificationsInfiniteScroll">
                    <Notification v-for="notification in notifications" :key="notification.id" :notification="notification"></Notification>
                </div>
                <span style="font-size: 20px; color: #b52a2f; margin-top: 30px; text-align: center " v-if="!notificationsPresent && !isLoadingNotifications">Nessun evento ricevuto</span>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment";
moment.locale('it')

import _ from 'lodash';

import Loading from 'vue-loading-overlay';

import Service from "@/components/zapmonitor_components/services/single_service.vue"

import ApiService from "@/javascript/api_service";
import Notification from "@/components/zapmonitor_components/services/notifications/single_notification.vue";

export default {
    name: 'ZMServices',
    components: {
        Notification,
        Loading,
        Service,
    },

    data () {
        return {
            search: '',
            skipPage: false,
            isLoadingServices: false,
            isLoadingNotifications: false,
            customer: {},
            products: [],
            services: [],
            notifications: [],
            notificationsPage: 1,
            refreshOptions: [
                {
                    value: 'never',
                    label: 'Autorefresh: Disabilitato',
                    refreshTime: null
                },
                {
                    value: '15s',
                    label: 'Ogni 15 secondi',
                    refreshTime: 15000
                },
                {
                    value: '30s',
                    label: 'Ogni 30 secondi',
                    refreshTime: 30000
                },
                {
                    value: '1m',
                    label: 'Ogni minuto',
                    refreshTime: 60000
                },
                {
                    value: '2m',
                    label: 'Ogni 2 minuti',
                    refreshTime: 120000
                }
            ],
            selectedRefreshOption: {
                value: 'never',
                label: 'Autorefresh: Disabilitato',
                refreshTime: null
            },
            showOnlyStatusChanges: false,
            selectedProduct: {},
            selectedService: {},
            statusOptions: [
                {
                    value: null,
                    label: 'Qualsiasi',
                },
                {
                    value: 'OK',
                    label: 'OK',
                },
                {
                    value: 'NON OK',
                    label: 'NON OK',
                },
                {
                    value: 'WARNING',
                    label: 'WARNING',
                },
                {
                    value: 'ERROR',
                    label: 'ERROR',
                },
                {
                    value: 'FATAL',
                    label: 'FATAL',
                },
            ],
            selectedStatusOption: {
                value: null,
                label: 'Qualsiasi',
            },
            viewOptions: [
                {
                    value: 'row',
                    icon: 'list'
                },
                {
                    value: 'block',
                    icon: 'table-cells-large'
                }
            ],
            selectedViewOption: 'row',
            lastTimestamp: new Date(),
            refreshFunction: null
        }
    },

    methods: {
        goBack () {
            let pagesBack = 1

            if (this.products.length == 1) {
                ++pagesBack
            }

            if (this.skipPage != undefined && this.skipPage == 'true') {
                ++pagesBack
            }

            this.$router.go(-pagesBack)
        },

        getLedSrc (serviceStatus) {
            return `/images/led_${serviceStatus}.png`
        },

        isSelectedRefreshOption (refreshOption) {
            return refreshOption.value == this.selectedRefreshOption.value
        },

        setSelectedRefreshOption (refreshOption) {
            this.selectedRefreshOption = refreshOption
        },

        selectService (service) {
          this.selectedService = service
        },

        onShowOnlyStatusChangesChange () {
            this.showOnlyStatusChanges = !this.showOnlyStatusChanges
            this.notificationsPage = 1
            this.notifications = []
            this.loadNotifications(this.selectedService.id)
        },

        refreshMethod () {
            this.refreshFunction = setInterval(() => {
                this.refreshData()
            }, this.selectedRefreshOption.refreshTime)
        },

        refreshData () {
            this.loadServices()
            this.lastTimestamp = new Date()
        },

        loadCustomer () {
            let customerId = this.$route.params.customer_id

            ApiService.fetchCustomer(customerId)
                .then((response) => {
                    this.customer = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        loadProducts () {
            let customerId = this.$route.params.customer_id

            ApiService.fetchProducts(customerId)
                .then((response) => {
                    this.products = response.data

                    if (!this.$route.params.product_id) {
                        this.selectedProduct = _.head(response.data)
                    }
                    else {
                     this.selectedProduct = _.find(response.data, (singleProduct) => {
                         return singleProduct.id == this.$route.params.product_id
                     })
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        loadServices () {
            let customerId = this.$route.params.customer_id
            let productId = this.$route.params.product_id

            this.isLoadingServices = true

            ApiService.fetchServices(customerId, productId)
                .then((response) => {
                    Object.assign(this.services, response.data)
                    Object.assign(this.selectedService, _.head(response.data))
                    this.loadNotifications(_.head(response.data).id)
                    this.isLoadingServices = false
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoadingServices = false
                })
        },

        loadNotifications (serviceId) {
            let customerId = this.$route.params.customer_id

            this.isLoadingNotifications = true

            ApiService.fetchNotifications(customerId, serviceId, this.notificationsPage, this.showOnlyStatusChanges)
                .then((response) => {
                    this.isLoadingNotifications = false
                    this.notifications = _.concat(this.notifications, response.data)
                })
                .catch((error) => {
                    this.isLoadingNotifications = false
                    console.log(error);
                })
        },

        serviceContractUpdated () {
            this.loadCustomer()
            this.loadServices()
            this.notifications = []
        },

        handleNotificationsInfiniteScroll (e) {
            if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight) {
                this.notificationsPage = this.notificationsPage + 1
                this.loadNotifications(this.selectedService.id)
            }
        },

        onChangeViewOption (value) {
            this.$cookies.set('selected_events_view_option', value);
            this.selectedViewOption = value
        },
    },

    computed: {
        lastTimestampText () {
            let formattedTimestamp = moment(this.lastTimestamp).format('LTS')
            return `Ultimo refresh: ${formattedTimestamp}`
        },

        searchedServices () {
            if (!this.search) return this.services

            return this.services.filter(item => {
                let serviceName = item.name || ""

                return serviceName.toLowerCase().includes(this.search.toLowerCase())
            })
        },

        filteredServices () {
            let filteredServices = _.filter(this.searchedServices, (singleService) => {
                return singleService.product_id == this.selectedProduct.id
            })

            if (!this.selectedStatusOption.value) return filteredServices

            if (this.selectedStatusOption.value == 'NON OK') {
                filteredServices = _.filter(filteredServices, (singleService) => {
                    return singleService.service_status.status != 'OK'
                })
            }
            else {
                filteredServices = _.filter(filteredServices, (singleService) => {
                    return singleService.service_status.status == this.selectedStatusOption.value
                })
            }

            return filteredServices
        },

        notificationsPresent () {
            return this.notifications && this.notifications.length > 0
        }
    },

    watch: {
        selectedRefreshOption (newValue) {
            this.$cookies.set('selected_refresh_option',newValue);
            clearInterval(this.refreshFunction)

            if (newValue.value != 'never') {
                this.refreshMethod()
            }
        },

        selectedService (newValue) {
            this.notificationsPage = 1
            this.notifications = []
            this.loadNotifications(newValue.id)
        },

        selectedProduct (newValue, oldValue) {
            if (! _.isEmpty(oldValue)) {
                let customerId = this.$route.params.customer_id
                this.$router.replace({ path: `/clienti/${customerId}/prodotti/${newValue.id}/servizi` })
                this.loadServices()
            }
        }
    },

    mounted () {
        if (this.$cookies.get('selected_refresh_option') != null) {
            this.selectedRefreshOption = this.$cookies.get('selected_refresh_option')  // return value
        }

        this.skipPage = this.$route.query.skipPage

        this.loadCustomer()
        this.loadProducts()
        this.loadServices()
    },

    beforeUnmount () {
        clearInterval(this.refreshFunction)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "src/stylesheet/zm_commons/zm_commons";

.zm-services {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.services-box {
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: auto;
}

.products-box {
    display: flex;
    flex: 2;
    flex-direction: column;
}

.services-list {
    display: flex;
    flex: 2;
    flex-direction: column;
    overflow: auto;
}

.events-box {
    display: flex;
    flex: 1;
    flex-direction: column;
    background: #f7f7f7;
    border: 1px solid #3d5171;
    margin: 0 20px 15px;
    overflow-x: auto;
}

.events-box-header {
    display: flex;
    margin: 15px;
}

.events-btn {
    border: 1px solid #3d5171;
    background: white;
    color: #3d5171;
    border-radius: 6px;

    &:hover {
        background: #ccc;
    }
}

.active {
    background: #3d5171;
    color: white;

    &:hover {
        background: #3d5171;
    }
}

.notifications-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

</style>