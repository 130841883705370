<template>
  <div class="zm-body">
      <router-view :key='$route.name'></router-view>
  </div>
</template>

<script>

export default {
    name: 'ZapMonitor'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*@import "vue-select/dist/vue-select.css";*/

.zm-body {
    display: flex;
    flex: 1;
    overflow: auto;
}
</style>
