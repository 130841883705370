/**
 * @module ApiModule
 */

// Importa le librerie necessarie: Axios per le chiamate HTTP e moment per la manipolazione delle date
import axios from 'axios';
import moment from "moment";
import VueCookies from 'vue-cookies';
import router from '../router.js';

// Imposta la localizzazione di moment in italiano
moment.locale('it');

// Crea un'istanza di Axios per le chiamate HTTP
const axiosInstance = axios.create();

function setupResponseInterceptor() {
    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response && (error.response.status === 404 || error.response.status == 401)) {
                // Rimuovi il cookie
                VueCookies.remove('zapmonitor-session_id');

                // Reindirizza l'utente alla pagina di login
                router.push('/login');
            }

            // Continua a lanciare l'errore per eventuali altre gestioni
            return Promise.reject(error);
        }
    );
}

setupResponseInterceptor();

/**
 * Funzione helper per effettuare chiamate GET con Axios.
 * @param {string} endpoint - L'endpoint dell'API da chiamare.
 * @param {Object} [params={}] - Parametri da passare nella query string.
 * @returns {Promise} Una promessa che risolve con la risposta dell'API.
 */
function apiGet(endpoint, params = {}) {
    return axiosInstance.get(endpoint, { params });
}

/**
 * Funzione helper per effettuare chiamate PATCH con Axios.
 * @param {string} endpoint - L'endpoint dell'API da chiamare.
 * @param {Object} [data={}] - Dati da inviare nel corpo della richiesta.
 * @returns {Promise} Una promessa che risolve con la risposta dell'API.
 */
function apiPatch(endpoint, data = {}) {
    return axiosInstance.patch(endpoint, data);
}

/**
 * Recupera la versione del backend.
 * @async
 * @returns {Promise<string>} Una promessa che risolve con la versione del backend.
 */
async function fetchBeVersion() {
    return apiGet('/api/version')
        .then((res) => {
            return res.data.version;
        })
        .catch((error) => {
            console.error(`Errore in fetchBeVersion: ${error}`);
            return "unknown";
        })
}

/**
 * Recupera la lista dei centri servizio.
 * @returns {Promise} Una promessa che risolve con la lista dei centri servizio.
 */
function fetchServiceCenters() {
    return apiGet('/api/service_centers');
}

/**
 * Recupera i dettagli di un centro servizio specifico.
 * @param {string} serviceCenterId - L'ID del centro servizio.
 * @returns {Promise} Una promessa che risolve con i dettagli del centro servizio.
 */
function fetchServiceCenter(serviceCenterId) {
    return apiGet(`/api/service_centers/${serviceCenterId}`);
}

/**
 * Recupera la lista dei clienti associati a un centro servizio.
 * @param {string} serviceCenterId - L'ID del centro servizio.
 * @returns {Promise} Una promessa che risolve con la lista dei clienti.
 */
function fetchCustomers(serviceCenterId) {
    return apiGet('/api/customers', { service_center_id: serviceCenterId });
}

/**
 * Recupera i dettagli di un cliente specifico.
 * @param {string} customerId - L'ID del cliente.
 * @returns {Promise} Una promessa che risolve con i dettagli del cliente.
 */
function fetchCustomer(customerId) {
    return apiGet(`/api/customers/${customerId}`);
}

/**
 * Recupera gli stati dei servizi di un centro servizio.
 * @param {string} serviceCenterId - L'ID del centro servizio.
 * @returns {Promise} Una promessa che risolve con gli stati dei servizi.
 */
function fetchServiceStatuses(serviceCenterId) {
    return apiGet('/api/service_status', { service_center_id: serviceCenterId });
}

/**
 * Recupera i prodotti associati a un cliente.
 * @param {string} customerId - L'ID del cliente.
 * @returns {Promise} Una promessa che risolve con i prodotti associati al cliente.
 */
function fetchProducts(customerId) {
    return apiGet('/api/products', { customer_id: customerId });
}

/**
 * Recupera i servizi associati a un cliente e a un prodotto.
 * @param {string} customerId - L'ID del cliente.
 * @param {string} productId - L'ID del prodotto.
 * @returns {Promise} Una promessa che risolve con i servizi associati.
 */
function fetchServices(customerId, productId) {
    return apiGet('/api/services', { customer_id: customerId, product_id: productId });
}

/**
 * Recupera le notifiche relative a un cliente e a un servizio.
 * @param {string} customerId - L'ID del cliente.
 * @param {string} serviceId - L'ID del servizio.
 * @param {number} page - La pagina delle notifiche da recuperare.
 * @param {boolean} compressList - Se comprimere o meno la lista delle notifiche.
 * @returns {Promise} Una promessa che risolve con le notifiche.
 */
function fetchNotifications(customerId, serviceId, page, compressList) {
    let dateFrom = moment().subtract(3, 'months');
    let dateTo = moment();
    let resultsPerPage = 30;

    let params = {
        customer_id: customerId,
        service_id: serviceId,
        page: page,
        results_per_page: resultsPerPage,
        compress_list: compressList,
    };

    if (compressList) {
        params['from_date'] = dateFrom;
        params['to_date'] = dateTo;
    }

    return apiGet('/api/notifications', params);
}

/**
 * Recupera i dettagli di un evento specifico.
 * @param {string} eventId - L'ID dell'evento.
 * @returns {Promise} Una promessa che risolve con i dettagli dell'evento.
 */
function fetchEvent(eventId) {
    return apiGet(`/api/events/${eventId}`);
}

/**
 * Aggiorna i dettagli di un cliente.
 * @param {string} customerId - L'ID del cliente.
 * @param {Object} params - Parametri da aggiornare.
 * @returns {Promise} Una promessa che risolve con la risposta dell'API.
 */
function updateCustomer(customerId, params) {
    return apiPatch(`/api/customers/${customerId}`, params);
}

/**
 * Aggiorna un contratto di servizio associato a un cliente.
 * @param {string} customerId - L'ID del cliente.
 * @param {string} serviceContractId - L'ID del contratto di servizio.
 * @param {Object} params - Parametri da aggiornare.
 * @returns {Promise} Una promessa che risolve con la risposta dell'API.
 */
function updateServiceContract(customerId, serviceContractId, params) {
    return apiPatch(`/api/customers/${customerId}/service_contract/${serviceContractId}`, params);
}

// Esporta le funzioni per poterle utilizzare in altri moduli
export default {
    fetchBeVersion,
    fetchServiceCenters,
    fetchServiceCenter,
    fetchCustomers,
    fetchCustomer,
    fetchServiceStatuses,
    fetchProducts,
    fetchServices,
    fetchNotifications,
    fetchEvent,
    updateCustomer,
    updateServiceContract
}
