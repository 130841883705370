<template>
    <div class="zm-services-status">
        <div class="timestamp-box">
            <div class="back-icon" @click="goBack()">
                <i class="fa-solid fa-circle-chevron-left"></i>
            </div>
            <div class="last-timestamp-text">{{lastTimestampText}}</div>
            <v-select
                    v-model="selectedRefreshOption"
                    label="label"
                    :options="refreshOptions"
                    :clearable="false"
                    style="width: 300px"></v-select>
        </div>
        <div class="header-text">
            <img style="margin-right: 10px; margin-bottom: 10px" :src="getLedSrc(serviceCenter.status)"/>
            {{serviceCenter.name}}: Lista servizi
        </div>
        <div class="service-link-text">
            <span  @click="goToCustomersView()" style="cursor: pointer">Vista clienti</span>
        </div>
        <div class="counter-box">
            <div class="single-status-counter" v-for="status in customerStatuses" :key="status">
                <div v-show="statusCounter(status) != 0">
                    <img style="margin-right: 5px; margin-bottom: 5px" :src="getLedSrc(status)"/>
                    <span style="font-size: 20px; font-weight: bold; margin-right: 15px">{{statusCounter(status)}}</span>
                </div>
            </div>
<!--            <div class="single-status-counter"  key="muted">-->
<!--                <div v-show="mutedStatusCounter != 0">-->
<!--                    <i class="fa-solid fa-bell-slash"></i>-->
<!--                    <span style="font-size: 20px; font-weight: bold; margin-right: 15px">{{mutedStatusCounter}}</span>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class="settings-box">
            <span style="font-size: 20px; font-weight: bold; margin-right: 10px">Stato</span>
            <v-select
                    v-model="selectedStatusOption"
                    label="label"
                    :options="statusOptions"
                    :clearable="false"
                    style="width: 300px"></v-select>
            <div class="search-box">
                <input class="search-input" v-model="search" placeholder="Cerca servizi" />
            </div>
        </div>
        <loading :active='isLoading' :is-full-page="true" style="align-self: center; margin-top: 200px"/>
        <div class="services-status-list" v-if="!isLoading && filteredServicesStatus.length > 0">
            <ServiceStatus v-for="serviceStatus in filteredServicesStatus" :key="`${serviceStatus.id}_${serviceStatus.service_contract.is_muted}`" :service-status="serviceStatus" @update-service-status-contract="serviceContractUpdated"></ServiceStatus>
        </div>
    </div>
</template>

<script>

import moment from "moment";
moment.locale('it')

import _ from 'lodash';

import ServiceStatus from "@/components/zapmonitor_components/service_statuses/single_service_status.vue"

import ApiService from "@/javascript/api_service";
import Loading from "vue-loading-overlay";

export default {
    name: 'ZMServicesStatus',
    components: {
        Loading,
        ServiceStatus,
    },

    data () {
        return {
            search: '',
            isLoading: false,
            serviceCenter: {},
            servicesStatus: [],
            customerStatuses: ['OK', 'WARNING', 'ERROR', 'FATAL', 'UNDEFINED'],
            refreshOptions: [
                {
                    value: 'never',
                    label: 'Autorefresh: Disabilitato',
                    refreshTime: null
                },
                {
                    value: '15s',
                    label: 'Ogni 15 secondi',
                    refreshTime: 15000
                },
                {
                    value: '30s',
                    label: 'Ogni 30 secondi',
                    refreshTime: 30000
                },
                {
                    value: '1m',
                    label: 'Ogni minuto',
                    refreshTime: 60000
                },
                {
                    value: '2m',
                    label: 'Ogni 2 minuti',
                    refreshTime: 120000
                }
            ],
            selectedRefreshOption: {
                value: 'never',
                label: 'Autorefresh: Disabilitato',
                refreshTime: null
            },
            statusOptions: [
                {
                    value: null,
                    label: 'Qualsiasi',
                },
                {
                    value: 'OK',
                    label: 'OK',
                },
                {
                    value: 'NON OK',
                    label: 'NON OK',
                },
                {
                    value: 'WARNING',
                    label: 'WARNING',
                },
                {
                    value: 'ERROR',
                    label: 'ERROR',
                },
                {
                    value: 'FATAL',
                    label: 'FATAL',
                },
            ],
            selectedStatusOption: {
                value: null,
                label: 'Qualsiasi',
            },
            lastTimestamp: new Date(),
            refreshFunction: null
        }
    },

    methods: {
        goBack () {
            this.$router.push(`/centri_servizi`)
        },

        getLedSrc (serviceStatusStatus) {
            return `/images/led_${serviceStatusStatus}.png`
        },

        isSelectedRefreshOption (refreshOption) {
            return refreshOption.value == this.selectedRefreshOption.value
        },

        setSelectedRefreshOption (refreshOption) {
            this.selectedRefreshOption = refreshOption
        },

        statusCounter (serviceStatus) {
            let servicesStatus = _.filter(this.servicesStatus, (singleService) => {
                return singleService.status == serviceStatus && !singleService.service_contract.is_muted
            })

            let mutedServicesStatus = _.filter(this.servicesStatus, (singleService) => {
                return singleService.status == serviceStatus && singleService.service_contract.is_muted
            })

            let counter = `${servicesStatus.length}`

            if (mutedServicesStatus.length > 0) {
                let muted = mutedServicesStatus.length == 1 ? 'silenziato' : 'silenziati'
                counter += ` (${mutedServicesStatus.length} ${muted})`
            }

            return counter
        },

        goToCustomersView () {
            this.$router.push(`/centri_servizi/${this.serviceCenter.id}/clienti`)
        },

        refreshMethod () {
            this.refreshFunction = setInterval(() => {
                this.refreshData()
            }, this.selectedRefreshOption.refreshTime)
        },

        refreshData () {
            this.loadServiceStatuses()
            this.lastTimestamp = new Date()
        },

        loadServiceCenter () {
            let serviceCenterId = this.$route.params.id

            ApiService.fetchServiceCenter(serviceCenterId)
                .then((response) => {
                    this.serviceCenter = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        loadServiceStatuses () {
            this.isLoading = true
            let serviceCenterId = this.$route.params.id

            ApiService.fetchServiceStatuses(serviceCenterId)
                .then((response) => {
                    // let orderedList = _.orderBy(response.data, 'name')
                    this.servicesStatus = response.data
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false
                })
        },

        serviceContractUpdated () {
            this.loadServiceCenter()
            this.loadServiceStatuses()
        }
    },

    computed: {
        lastTimestampText () {
            let formattedTimestamp = moment(this.lastTimestamp).format('LTS')
            return `Ultimo refresh: ${formattedTimestamp}`
        },

        searchedServicesStatus () {
            if (!this.search) return this.servicesStatus

            return this.servicesStatus.filter(item => {
                let serviceStatusCustomerName = item.customer.name || ""
                let serviceStatusServiceName = item.service.name || ""

                return serviceStatusCustomerName.toLowerCase().includes(this.search.toLowerCase()) || serviceStatusServiceName.toLowerCase().includes(this.search.toLowerCase())
            })
        },

        filteredServicesStatus () {

            if (!this.selectedStatusOption.value) return this.searchedServicesStatus

            let filteredServicesStatus = this.searchedServicesStatus

            if (this.selectedStatusOption.value == 'NON OK') {
                filteredServicesStatus = _.filter(filteredServicesStatus, (singleServiceStatus) => {
                    return singleServiceStatus.status != 'OK'
                })
            }
            else {
                filteredServicesStatus = _.filter(filteredServicesStatus, (singleServiceStatus) => {
                    return singleServiceStatus.status == this.selectedStatusOption.value
                })
            }

            return filteredServicesStatus
        },

        mutedStatusCounter () {
            let servicesStatus = _.filter(this.servicesStatus, (singleService) => {
                return singleService.service_contract.is_muted
            })

            return servicesStatus.length
        }
    },

    watch: {
        selectedRefreshOption (newValue) {
            this.$cookies.set('selected_refresh_option',newValue);
            clearInterval(this.refreshFunction)

            if (newValue.value != 'never') {
                this.refreshMethod()
            }
        }
    },

    mounted () {
        if (this.$cookies.get('selected_refresh_option') != null) {
            this.selectedRefreshOption = this.$cookies.get('selected_refresh_option')  // return value
        }

        this.loadServiceCenter()
        this.loadServiceStatuses()
    },

    beforeUnmount () {
        clearInterval(this.refreshFunction)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "src/stylesheet/zm_commons/zm_commons";

.zm-services-status {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.services-status-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

</style>