<template>
    <div class="zm-single-notification">
        <div class="notification-header" @click="openNotificationDetail()">
<!--            <img style="margin-right: 15px" :src="getLedSrc(notification.event.result)"/>-->
            <div class="status-bar" :style="{ background: statusBarColor(notification.event.result) }"></div>
            <div class="notification-description">
                <span>{{notification.event.event_type}} ({{getHeartbeatTimestamp(notification.event.timestamp)}})</span>
            </div>
            <i class="fas fa-heartbeat" style="font-size: 25px; margin-left: auto; margin-right: 10px" v-if="notification.event.heartbeat"></i>
        </div>
        <div class="notification-body" v-show="showNotificationBody">
            <span style="margin-top: 15px; font-size: 18px; font-weight: bold; text-align: center">Messaggio</span>
            <div class="message-box">
                <span>{{event.message}}</span>
            </div>
            <span style="margin-top: 15px; font-size: 18px; font-weight: bold; text-align: center">Data e ora</span>
            <span style="margin-top: 5px; margin-bottom: 10px; font-size: 14px; text-align: center">{{getHeartbeatTimestamp(event.timestamp)}}</span>
            <span style="margin-top: 15px; font-size: 18px; font-weight: bold; text-align: center; cursor: pointer" @click="toggleAllData()">
                <span v-if="showAllData"><i class="fa fa-compress"></i></span><span v-if="!showAllData"><i class="fa fa-expand"></i></span>&nbsp;Data</span>

            <div class="data-box">
                <data-node v-for="(group, key) in event.data" :key="key" :node="key" :data="group" ref="dataNodes"></data-node>
            </div>
        </div>
    </div>
</template>

<script>

import moment from "moment";
moment.locale('it')

import _ from 'lodash';

import DataNode from "@/components/zapmonitor_components/services/notifications/single_notification_event_data_node.vue"

import ApiService from "@/javascript/api_service";

export default {

    components: {
        DataNode
    },

    props: {
        notification: Object
    },

    data () {
        return {
            showNotificationBody: false,
            event: {},
            showAllData: true
        }
    },

    methods: {
        toggleAllData() {
            this.showAllData = !this.showAllData;
            this.$refs.dataNodes.forEach(dataNode => {
                dataNode.showChildrenRecursive(this.showAllData);
            });
        },
        getLedSrc (notificationStatus) {
            return `/images/led_${notificationStatus}.png`
        },

        getHeartbeatTimestamp (hbTimestamp) {
            return `${moment(hbTimestamp).format('DD MMM YYYY HH:mm:ss')}`
        },

        openNotificationDetail () {
            this.showNotificationBody = !this.showNotificationBody

            if (_.isEmpty(this.event)) {
                this.loadEvent()
            }
        },

        loadEvent () {
            let eventId = this.notification.event.id

            ApiService.fetchEvent(eventId)
                .then((response) => {
                    this.event = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        statusBarColor (status) {
            switch (status) {
                case 'OK':
                    return '#198754'
                case 'WARNING':
                    return '#ffc107'
                case 'ERROR':
                    return '#dc3545'
                case 'FATAL':
                    return '#000'
                default:
                    return '#ced4da'
            }
        }
    },

    computed: {
        myClass() {
            return this.showAllData ? "fa fa-compress" : "fa fa-maximize"
        },
    },

    mounted () {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zm-single-notification {
    display: flex;
    flex-direction: column;
    margin: 5px 10px;
    border: 1px solid #3d5171;

    &:hover {
        background: #ccc;
    }
}

.notification-header {
    display: flex;
    flex-direction: row;
    height: 80px;
    /*padding: 10px 20px;*/
    align-items: center;
    cursor: pointer;

    &:hover {
        background: #ccc;
        /*border-radius: 6px;*/
        /*color: #b52a2f;*/
    }
}

.status-bar {
    width: 20px;
    height: 100%;
    margin-right: 10px;
}

.notification-description {
    font-size: 14px;
}

.notification-body {
    display: flex;
    flex-direction: column;
    background: white;
    border-top: 1px solid #3d5171;
}

.message-box {
    margin: 10px;
    padding: 10px;
    border: 1px solid #3d5171;
    text-align: center
}

.data-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px;
    padding: 10px 0;
    border: 1px solid #3d5171;
    overflow-x: auto;
}

</style>