<template>
    <div class="zm-single-service-status">
        <span class="mute-icon" @click="updateCustomerMuted(serviceStatus.service_contract)">
            <i :class="muteIcon(serviceStatus.service_contract)"></i>
        </span>
        <div class="clickable-row" @click="goToServiceStatusDetail">
            <img style="height: 30px; margin-right: 30px" :src="getLedSrc(serviceStatus.status)"/>
            <div class="service-status-description">
                <span>{{serviceStatus.customer.name}} - {{serviceStatus.service.name}}</span>
            </div>
            <div class="service-status-notes">
                <span class="notes-text">{{serviceStatus.status_message}}</span>
            </div>
        </div>
    </div>
</template>

<script>


import ApiService from "@/javascript/api_service";

export default {

    props: {
        serviceStatus: Object
    },

    methods: {
        getLedSrc (serviceStatusStatus) {
            return `/images/led_${serviceStatusStatus}.png`
        },

        goToServiceStatusDetail () {
            this.$router.push(`/clienti/${this.serviceStatus.customer_id}/prodotti/${this.serviceStatus.service.product_id}/servizi`)
        },

        muteIcon (serviceContract) {
            if (serviceContract.is_muted) {
                return "fa-solid fa-bell-slash"
            }

            return "fa-solid fa-bell"
        },

        updateCustomerMuted (serviceContract) {
            ApiService.updateServiceContract(this.serviceStatus.customer_id, serviceContract.id, {is_muted: !serviceContract.is_muted})
                .then((response) => {
                    this.$emit('update-service-status-contract');
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    },

    computed: {

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.zm-single-service-status {
    display: flex;
    flex-direction: row;
    height: 80px;
    margin: 0 10px;
    align-items: center;
    cursor: pointer;

    &:nth-child(2n-1) {
        background-color: #f1f1f1;
    }

    &:hover {
        background: #ccc;
        border-radius: 6px;
    }
}

.service-status-description {
    font-size: 24px;
    font-weight: bold;
}

.clickable-row {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 20px;
    align-items: center;
}

.mute-icon {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 22px;
    cursor: pointer;
    pointer-events: all;
    width: 30px;
    padding: 20px;
}

.service-status-notes {
    /*display: flex;*/
    justify-content: flex-start;
    align-items: center;
    font-size: 12px;
    width: 50%;
    background: #f7f7f7;
    border: 1px solid #3d5171;
    border-radius: 6px;
    margin-left: auto;
    height: 45px;
}

.notes-text {
    font-size: 14px;
    font-weight: bold;
    margin-left: 10px;
}

</style>