<template>
    <div class="zm-products">
        <div class="timestamp-box">
            <div class="back-icon" @click="goBack()">
                <i class="fa-solid fa-circle-chevron-left"></i>
            </div>
            <div class="last-timestamp-text">{{lastTimestampText}}</div>
            <v-select
                    v-model="selectedRefreshOption"
                    label="label"
                    :options="refreshOptions"
                    :clearable="false"
                    style="width: 300px"></v-select>
        </div>
        <div class="header-text">
            <img style="margin-right: 10px; margin-bottom: 10px" :src="getLedSrc(customer.status)"/>
            {{customer.name}}: Lista prodotti
        </div>
        <div class="settings-box">
            <div class="search-box">
                <input class="search-input" v-model="search" placeholder="Cerca prodotti" />
            </div>
        </div>
        <loading :active='isLoading' :is-full-page="true" style="align-self: center; margin-top: 200px"/>
        <div class="products-list">
            <Product v-for="product in filteredProducts" :key="product.id" :customer="customer" :product="product"></Product>
        </div>
    </div>
</template>

<script>

import moment from "moment";
moment.locale('it')

import _ from 'lodash';

import Product from "@/components/zapmonitor_components/products/single_product.vue"

import ApiService from "@/javascript/api_service";
import Loading from "vue-loading-overlay";

export default {
    name: 'ZMProducts',
    components: {
        Loading,
        Product,
    },

    data () {
        return {
            search: '',
            skipPage: false,
            isLoading: false,
            customer: {},
            products: [],
            clientStatuses: [
                {
                    value: 'OK',
                    icon: 'success'
                },
                {
                    value: 'WARNING',
                    icon: 'warning'
                },
                {
                    value: 'ERROR',
                    icon: 'danger'
                },
                {
                    value: 'FATAL',
                    icon: 'dark'
                },
                {
                    value: 'NON OK',
                    icon: 'secondary'
                },
            ],
            refreshOptions: [
                {
                    value: 'never',
                    label: 'Autorefresh: Disabilitato',
                    refreshTime: null
                },
                {
                    value: '15s',
                    label: 'Ogni 15 secondi',
                    refreshTime: 15000
                },
                {
                    value: '30s',
                    label: 'Ogni 30 secondi',
                    refreshTime: 30000
                },
                {
                    value: '1m',
                    label: 'Ogni minuto',
                    refreshTime: 60000
                },
                {
                    value: '2m',
                    label: 'Ogni 2 minuti',
                    refreshTime: 120000
                }
            ],
            selectedRefreshOption: {
                value: 'never',
                label: 'Autorefresh: Disabilitato',
                refreshTime: null
            },
            enableStatusOptions: [
                {
                    value: null,
                    label: 'Qualsiasi',
                },
                {
                    value: true,
                    label: 'Solo attivi',
                },
                {
                    value: false,
                    label: 'Solo inattivi',
                },
            ],
            selectedEnableStatusOption: {
                value: true,
                label: 'Solo attivi',
            },
            statusOptions: [
                {
                    value: null,
                    label: 'Qualsiasi',
                },
                {
                    value: 'OK',
                    label: 'OK',
                },
                {
                    value: 'NON OK',
                    label: 'NON OK',
                },
                {
                    value: 'WARNING',
                    label: 'WARNING',
                },
                {
                    value: 'ERROR',
                    label: 'ERROR',
                },
                {
                    value: 'FATAL',
                    label: 'FATAL',
                },
            ],
            selectedStatusOption: {
                value: null,
                label: 'Qualsiasi',
            },
            lastTimestamp: new Date(),
            refreshFunction: null
        }
    },

    methods: {
        goBack () {
            let pagesBack = 1

            if (this.$route.query.skipPage) {
                ++pagesBack
            }

            this.$router.go(-pagesBack)
        },

        getLedSrc (productStatus) {
            return `/images/led_${productStatus}.png`
        },

        isSelectedRefreshOption (refreshOption) {
            return refreshOption.value == this.selectedRefreshOption.value
        },

        setSelectedRefreshOption (refreshOption) {
            this.selectedRefreshOption = refreshOption
        },

        refreshMethod () {
            this.refreshFunction = setInterval(() => {
                this.refreshData()
            }, this.selectedRefreshOption.refreshTime)
        },

        refreshData () {
            this.loadProducts()
            this.lastTimestamp = new Date()
        },

        loadCustomer () {
            let customerId = this.$route.params.id

            ApiService.fetchCustomer(customerId)
                .then((response) => {
                    this.customer = response.data
                })
                .catch((error) => {
                    console.log(error);
                })
        },

        loadProducts () {
            this.isLoading = true
            let customerId = this.$route.params.id

            ApiService.fetchProducts(customerId)
                .then((response) => {

                    if (response.data.length == 1) {
                        let productId = _.head(response.data).id
                        this.isLoading = false
                        this.$router.push(`/clienti/${customerId}/prodotti/${productId}/servizi?skipPage=${this.$route.query.skipPage}`)
                    }

                    this.products = response.data
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false
                })
        }
    },

    computed: {
        lastTimestampText () {
            let formattedTimestamp = moment(this.lastTimestamp).format('LTS')
            return `Ultimo refresh: ${formattedTimestamp}`
        },

        searchedProducts () {
            if (!this.search) return this.products

            return this.products.filter(item => {
                let productName = item.name || ""

                return productName.toLowerCase().includes(this.search.toLowerCase())
            })
        },

        filteredProducts () {
            return this.searchedProducts
        },
    },

    watch: {
        selectedRefreshOption (newValue) {
            this.$cookies.set('selected_refresh_option',newValue);
            clearInterval(this.refreshFunction)

            if (newValue.value != 'never') {
                this.refreshMethod()
            }
        }
    },

    mounted () {
        if (this.$cookies.get('selected_refresh_option') != null) {
            this.selectedRefreshOption = this.$cookies.get('selected_refresh_option')  // return value
        }

        this.loadCustomer()
        this.loadProducts()
    },

    beforeUnmount () {
        clearInterval(this.refreshFunction)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "src/stylesheet/zm_commons/zm_commons";

.zm-products {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.products-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

</style>