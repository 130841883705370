<template>
    <div class="zm-service-centers">
        <div class="timestamp-box">
            <div class="last-timestamp-text">{{lastTimestampText}}</div>
            <v-select
                v-model="selectedRefreshOption"
                label="label"
                :options="refreshOptions"
                :clearable="false"
                style="width: 300px"></v-select>
        </div>
        <div class="header-text">Lista centro servizi</div>
        <div class="settings-box">
            <button
                type="button"
                class="graph-btn"
                v-for="option in viewOptions"
                :key="option.value"
                :class="{'active': selectedViewOption === option.value}"
                @click="onChangeViewOption(option.value)">
                <i style="font-size: 18px" :class="`fa-solid fa-${option.icon}`"></i>
            </button>
            <div class="search-box">
                <input class="search-input" v-model="search" placeholder="Cerca centro servizi" />
            </div>
        </div>
        <loading :active='isLoading' :is-full-page="true"  style="align-self: center; margin-top: 200px"/>
        <div class="service-centers-list" v-show="selectedViewOption == 'row'">
            <ServiceCenterRow v-for="serviceCenter in searchedServiceCenters" :key="`${serviceCenter.id}_row`" :service-center="serviceCenter"></ServiceCenterRow>
        </div>
        <div class="service-centers-grid" v-show="selectedViewOption == 'block'">
            <ServiceCenterBlock v-for="serviceCenter in searchedServiceCenters" :key="`${serviceCenter.id}_block`" :service-center="serviceCenter"></ServiceCenterBlock>
        </div>
    </div>
</template>

<script>

import moment from "moment";
moment.locale('it')

import _ from 'lodash';

import Loading from 'vue-loading-overlay';

import ServiceCenterRow from "@/components/zapmonitor_components/service_centers/single_service_center_row.vue"
import ServiceCenterBlock from "@/components/zapmonitor_components/service_centers/single_service_center_block.vue"

import ApiService from "@/javascript/api_service";

// import { config } from 'config';

export default {
    name: 'ZMServiceCenters',
    components: {
        Loading,
        ServiceCenterRow,
        ServiceCenterBlock,
    },

    data () {
        return {
            search: '',
            isLoading: false,
            viewOptions: [
                {
                    value: 'row',
                    icon: 'list'
                },
                {
                    value: 'block',
                    icon: 'table-cells-large'
                }
            ],
            selectedViewOption: 'row',
            serviceCenters: [],
            refreshOptions: [
                {
                    value: 'never',
                    label: 'Autorefresh: Disabilitato',
                    refreshTime: null
                },
                {
                    value: '15s',
                    label: 'Ogni 15 secondi',
                    refreshTime: 15000
                },
                {
                    value: '30s',
                    label: 'Ogni 30 secondi',
                    refreshTime: 30000
                },
                {
                    value: '1m',
                    label: 'Ogni minuto',
                    refreshTime: 60000
                },
                {
                    value: '2m',
                    label: 'Ogni 2 minuti',
                    refreshTime: 120000
                }
            ],
            selectedRefreshOption: {
                value: 'never',
                label: 'Autorefresh: Disabilitato',
                refreshTime: null
            },
            lastTimestamp: new Date(),
            refreshFunction: null
        }
    },

    methods: {
        isSelectedRefreshOption (refreshOption) {
            return refreshOption.value == this.selectedRefreshOption.value
        },

        setSelectedRefreshOption (refreshOption) {
            this.selectedRefreshOption = refreshOption
        },

        refreshMethod () {
            this.refreshFunction = setInterval(() => {
                this.refreshData()
            }, this.selectedRefreshOption.refreshTime)
        },

        onChangeViewOption (value) {
            this.$cookies.set('selected_view_option', value);
            this.selectedViewOption = value
        },

        refreshData () {
            this.loadServiceCenters()
            this.lastTimestamp = new Date()
        },

        loadServiceCenters () {
            // this.isLoading = true
            ApiService.fetchServiceCenters()
                .then((response) => {
                    let orderedServiceCenters = _.orderBy(response.data, 'name')
                    this.serviceCenters = orderedServiceCenters
                    this.isLoading = false
                })
                .catch((error) => {
                    console.log(error);
                    this.isLoading = false
                })
        }
    },

    computed: {
        lastTimestampText () {
            let formattedTimestamp = moment(this.lastTimestamp).format('LTS')
            return `Ultimo refresh: ${formattedTimestamp}`
        },

       searchedServiceCenters () {
           if (!this.search) return this.serviceCenters

           return this.serviceCenters.filter(item => {
               let serviceCenterName = item.name || ""

               return serviceCenterName.toLowerCase().includes(this.search.toLowerCase())
           })
       },

        errorServiceCentersCount () {
            let errorServiceCenters = _.filter(this.serviceCenters, (singleServiceCenter) => {
                return singleServiceCenter.status == 'ERROR'
            })

            return errorServiceCenters.length

        },

        totalCustomersAndServicesMuted () {
            let customersMutedCount = _.reduce(this.serviceCenters, function(sum, singleServiceCenter) {
                return sum + singleServiceCenter.customer_muted_counter;
            }, 0);

            let servicesMutedCount = _.reduce(this.serviceCenters, function(sum, singleServiceCenter) {
                return sum + singleServiceCenter.service_muted_counter;
            }, 0);

            let customer = customersMutedCount == 1 ? 'cliente' : 'clienti '
            let service = servicesMutedCount == 1 ? 'servizio silenziato' : 'servizi silenziati'

            return `${servicesMutedCount} ${service} in ${customersMutedCount} ${customer}`
        },
    },

    watch: {
        selectedRefreshOption (newValue) {
            this.$cookies.set('selected_refresh_option',newValue);
            clearInterval(this.refreshFunction)

            if (newValue.value != 'never') {
                this.refreshMethod()
            }
        }
    },

    mounted () {
        if (this.$cookies.get('selected_refresh_option') != null) {
            this.selectedRefreshOption = this.$cookies.get('selected_refresh_option')  // return value
        }

        if (this.$cookies.get('selected_view_option') != null) {
            this.selectedViewOption = this.$cookies.get('selected_view_option')
        }

        // this.$cookies.set('x-api-token', '')

        this.loadServiceCenters()

    },

    beforeUnmount () {
        clearInterval(this.refreshFunction)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@import "src/stylesheet/zm_commons/zm_commons";

.view-select-row {
    display: flex;
    margin: 15px 0;
    justify-content: center;
}

.zm-service-centers {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
}

.service-centers-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: auto;
    padding: 5px;
}

.service-centers-grid {
    display: grid;
    flex: 1;
    overflow: auto;
    grid-template-rows: 240px; /* top row height */
    grid-auto-rows: 240px; /* other rows height */
    grid-template-columns: repeat(5, 1fr);
    padding: 5px;
}

</style>