// import VueRouter from 'vue-router'
import {createRouter, createWebHashHistory} from "vue-router"

import Login from './components/zapmonitor_components/login/index.vue';
import ZMServiceCenter from './components/zapmonitor_components/service_centers/index.vue';
import ZMCustomers from "@/components/zapmonitor_components/customers/index.vue";
import ZMServiceStatuses from "@/components/zapmonitor_components/service_statuses/index.vue";
import ZMProducts from "@/components/zapmonitor_components/products/index.vue";
import ZMServices from "@/components/zapmonitor_components/services/index.vue";
import ZMStatistics from "@/components/zapmonitor_components/statistics/index.vue";

// 1. Define route components.
// These can be imported from other files
const UserPermissionManager = { template: '<div>Home</div>' }

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes = [
    {
        name: 'login',
        path: '/login',
        component: Login
    },
    {
        name: 'root',
        path: '/',
        component: ZMServiceCenter,
        meta: {
            requiresAuth: true
        }
        },
    {
        name: 'statistiche',
        path: '/statistiche',
        component: ZMStatistics,
        meta: {
            requiresAuth: true
        }
        },
    {
        name: 'user_manager',
        path: '/user_manager',
        component: UserPermissionManager,
        meta: {
            requiresAuth: true
        }
        },
    {
        name: 'centri_servizi',
        path: '/centri_servizi',
        component: ZMServiceCenter,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'clienti',
        path: '/centri_servizi/:id/clienti',
        component: ZMCustomers,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'stato_servizi',
        path: '/centri_servizi/:id/stato_servizi',
        component: ZMServiceStatuses,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'prodotti',
        path: '/clienti/:id/prodotti',
        component: ZMProducts,
        meta: {
            requiresAuth: true
        }
    },
    {
        name: 'servizi',
        path: '/clienti/:customer_id/prodotti/:product_id/servizi',
        component: ZMServices,
        meta: {
            requiresAuth: true
        }
    },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes: routes, // short for `routes: routes`
})

// router.beforeEach((to, from, next) => {

//     const publicPages = ['/login'];
//     const authRequired = !publicPages.includes(to.path);
//     const tokenPresent = $cookies.get('zapmonitor-session_id') != null

//     if (authRequired && !tokenPresent) {
//         window.location.href = `${window.location.origin}/#/login`;
//     }
//     else {
//         next() // go to wherever I'm going
//     }

// })

export default router;
