const formattedMonthShort =  {
    jan: "Gen",
    feb: "Feb",
    mar: "Mar",
    apr: "Apr",
    may: "Mag",
    jun: "Giu",
    jul: "Lug",
    aug: "Ago",
    sep: "Set",
    oct: "Ott",
    nov: "Nov",
    dec: "Dic"
}

const formattedMonth =  {
    January: "Gennaio",
    February: "Febbraio",
    March: "Marzo",
    April: "Aprile",
    May: "Maggio",
    June: "Giugno",
    July: "Luglio",
    August: "Agosto",
    September: "Settembre",
    October: "Ottobre",
    November: "Novembre",
    December: "Dicembre"
}

function toItalian(date) {
    let formattedDate = date.split(/[, ]+/);

    switch (formattedDate.length) {
        case 3:
            formattedDate[1] = formattedMonthShort[`${formattedDate[1]}`]
            return formattedDate.join(' ')
        case 2:
            formattedDate[0] = formattedMonth[`${formattedDate[0]}`]
            return formattedDate.join(' ')
        default:
            return date
    }
}

export default {
    toItalian
};